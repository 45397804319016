<template>
  <div class="view-profile">
    <v-row>
      <v-col><h1>Profile</h1></v-col>
    </v-row>
    <div class="profile-layout">
      <div class="avatar-section">
        <div class="avatar">
          <img :src="userAvatarPath || '/static/img/user_icon.png'" alt="Avatar">
        </div>
        <div class="avatar-links">
          <a href="#" @click="viewImage">View Image</a>
          <span class="link-separator">|</span>
            <a href="#" @click.prevent="openImageFileDialog">Update Image</a>
          <input ref="fileInput" type="file" style="display: none" @change="handleImageUpload">
        </div>
      </div>
      <div class="info-section">
        <div class="input-field">
          <h5>Display Name:</h5>
          <input type="text" v-model="displayName"  @change="updateDetails('displayName')" :placeholder="getDisplayNamePlaceholder" class="display-name">
        </div>
        <div class="theme-switcher">
          <h5>Theme:</h5>
          <span class="mode-label">Light</span>
          <label class="switch">
            <input type="checkbox" v-model="theme_value" @change="updateDetails('theme')">
            <span class="slider round"></span>
          </label>
          <span class="mode-label">Dark</span>
        </div>
        <div class="records-dropdown">
          <h5>Records per page: </h5>
          <select v-model="selectedRecords" @change="updateDetails('record')" class="custom-dropdown">
            <option v-for="option in recordOptions" :value="option.value" :key="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapActions, mapState} from "pinia";
import {useStore} from '@/store';
import {useThemeSwitcher} from '@/store/theme';

export default {
  name: 'ViewProfile',
  setup() {
    const {theme, toggleTheme, currentTheme} = useThemeSwitcher();
    return {
      theme, toggleTheme, currentTheme
    }
  },
  async mounted() {
    try {
      await this.fetchDefaultSettings()
      const defaultValues = this.defaultSettings;
      for(var i=0;i<defaultValues.length;i++)
      {
          var key=defaultValues[i]["name"]
          if (key === "Number of Records") {
              const records =JSON.parse(defaultValues[i]["possible_values"]).map(value => ({
                label: String(value),
                value: value
                }));
            this.recordOptions = records
            this.selectedRecords = defaultValues[i]["default_value"];
          }
          if (key === "Theme") {
              this.theme_value = defaultValues[i]["default_value"];
          }
          if (key === "Display Name") {
            this.displayName = defaultValues[i]["default_value"];
          }
      }
      await this.fetchUserSettings()
      let user_settings= this.extractUserSettings(this.userSettings)
      if(user_settings)
      {
        if(user_settings["display_name"])
        {
          this.displayName = user_settings["display_name"];
        }
        if(user_settings["theme"]) {
          this.theme_value = user_settings["theme"] === "dark";
        }
        if(user_settings["numberofrecords"]) {
          this.selectedRecords = user_settings["numberofrecords"]
        }
      }
    } catch (error) {
      console.log("Error fetching data: ", error);
    }
  },
  data() {
    return {
      displayName: '',
      darkMode: false,
      theme_value:'',
      recordOptions: [],
      userSettingsValues: null,
      selectedRecords: null
    };
  },
  watch: {
    'userSettingsValues.numberofrecords'(newValue) {
      this.selectedRecords = newValue;
    }
  },
  computed: {
    ...mapState(useStore, {
      userSettings: 'userSettings',
      defaultSettings: 'defaultSettings',
      userAvatarPath:'userAvatarPath'
    }),
    getDisplayNamePlaceholder() {
      if (this.userSettingsValues && this.userSettingsValues['display_name']) {
        return this.userSettingsValues['display_name'] || 'Enter your display name';
      }
      return 'Enter your display name';
    }
  },
  methods: {
    ...mapActions(useStore, {
      fetchUserSettings: 'fetchUserSettings',
      fetchDefaultSettings: 'fetchDefaultSettings',
      viewUserImage: 'viewUserImage',
      updateUserImage: 'updateUserImage',
      updateUserDetails:'updateUserDetails'
    }),
        openImageFileDialog() {
      this.$refs.fileInput.click();
    },
    async handleImageUpload(event) {
      let response= await this.updateUserImage(event)
      if (response.status ===200) {
        if (response && response.data && response.data.link) {
          this.userAvatarPath = response.data.link+'?' + Date.now();
          sessionStorage.setItem('userAvatarPath',this.userAvatarPath);
        }
      }
    },
    viewImage() {
      const imageUrl = this.userAvatarPath+'?' + Date.now();
      window.open(imageUrl, 'User Image', 'width=400,height=400');
    },
    async updateDetails(type){
      let request;
      if(type === "displayName"){
        request={"settings_name":"Display Name","settings_value":this.displayName}
      }
      else if (type==="record"){
           request={"settings_name":"Number of Records","settings_value":this.selectedRecords}
      }
      else if (type==="theme"){
        let theme;
        if(this.theme_value===true)
        {
          theme="dark"
        }
        else{
           theme="light"
        }
          request={"settings_name":"Theme","settings_value":theme}
        let themeSet = sessionStorage.getItem('theme')
                if (themeSet != theme)
        this.toggleTheme()
      }
      let response= await this.updateUserDetails(request)
      if (response.status ===200) {
        console.log("Records updated")
      }
      else{
        console.log("Error occured while updating records")
      }
    },
    extractUserSettings(settingsArray) {
      const userSettings = {};
      settingsArray.forEach(setting => {
        const name = setting.setting.name;
        const value = setting.value;

        switch (name) {
          case "Theme":
            userSettings.theme = value;
            break;
          case "Number of Records":
            userSettings.numberofrecords = parseInt(value, 10);
            break;
          case "Display Name":
            userSettings.display_name = value;
            break;
          case "Profile pic":
            userSettings.profile_pic=value;
            break;
          default:
            console.log(`Unknown setting: ${name}`);
        }
      });
      return userSettings;
    }
  }
};
</script>

<style>

.display-name {
  width: 200px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
}

.header h1 {
  margin: 100;
}

.input-field, .theme-switcher, .records-dropdown {
  display: flex;
  align-items: center;
}

.input-field h5, .theme-switcher h5, .records-dropdown h5 {
  margin-right: 10px;
}

.theme-switcher {
  display: flex;
  align-items: center;
}

.input-field {
  display: flex;
  align-items: center;
}

.mode-label {
  margin: 0 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.records-dropdown {
  display: flex;
  align-items: center;
}

.records-dropdown h3 {
  margin-right: 10px;
}

.custom-dropdown {
  width: 150px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24" height="24"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px center;
  cursor: pointer;
}

.custom-dropdown:hover {
  border-color: #999;
}

.view-profile {
  min-height: 80vh;
  display: flow;
  flex-direction: column;
}

.profile-layout {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.avatar-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
}

.info-section {
  flex: 3;
  padding-left: 20px;
}

.info-section {
  flex: 3;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.theme-switcher, .records-dropdown, .input-field {
  width: 100%;
  margin-bottom: 20px;

}

.avatar-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.avatar-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.avatar-links a {
  font-size: 0.8em;
  color: #0366d6;
  text-decoration: none;
  padding: 0 5px;
}

.avatar-links a:hover {
  text-decoration: underline;
}

.link-separator {
  padding: 0 5px;
  color: #000;
}

.radio-option input[type="radio"] {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.radio-option label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.radio-option label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 2px solid #d1d3d4;
  border-radius: 50%;
  background-color: white;
}

.radio-option input[type="radio"]:checked + label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0366d6;
}

.checkbox-option input[type="checkbox"] {
  margin-right: 10px;
}


</style>
