import httpClient from '@/utils/request';
import {
    GET_INFRASTRUCTURES_URL,
    GET_INFRASTRUCTURE_DETAILS_URL,
    GET_PROVISIONS_URL,
    GET_SURVEY_LATESTYEAR_URL,
    GET_AVAILABLE_SURVEY_YEARS_URL, GET_INFRASTRUCTURES_STRUCTURE_IDS_URL,
    GET_STATISTICS_URL, CHANGE_PASSWORD_URL,
    GET_USER_SETTINGS,
    GET_DEFAULT_SETTINGS, ORG_INFRA_URL, UPDATE_USER_IMAGE,UPDATE_USER_DETAILS
}
    from '@/config/apiconfig';

 async function getInfrastructure_StructureNumberInfo (parameters){
     const response =
         await httpClient.get(GET_INFRASTRUCTURES_STRUCTURE_IDS_URL, {
             params: parameters,
         }).
    then(function(response){
        return  response.data;
    }).catch(function (error) {
        // handle error
        console.log(error);
        });
   return response;
}
 async function getInfrastructure (parameters){
     const response =
         await httpClient.get(GET_INFRASTRUCTURES_URL, {
             params: parameters,
         }).
    then(function(response){
        return  response.data;
    }).catch(function (error) {
        // handle error
        console.log(error);
        });
   return response;
}

async function getInfrastructureDetails (id,parameters){
   
    const response =
        await httpClient.get(GET_INFRASTRUCTURE_DETAILS_URL+id, 
            { 
                params: parameters
            }
        ).
   then(function(response){
       return  response.data;
   }).catch(function (error) {
       // handle error
       console.log(error);
       });
  return response;
}


async function getSurveyLatestYear (){
    const response =
        await httpClient.get(GET_SURVEY_LATESTYEAR_URL, {       
        }).
   then(function(response){
       return  response.data;
   }).catch(function (error) {
       // handle error
       console.log(error);
       });
  return response;
}

async function getAvailableSurveyYears (){
    const response =
        await httpClient.get(GET_AVAILABLE_SURVEY_YEARS_URL, {
        }).
   then(function(response){
       return  response.data;
   }).catch(function (error) {
       // handle error
       console.log(error);
       });
  return response;
}

async function getInfrastructureNext (nextURL){
    const response =
        await httpClient.get(nextURL).
   then(function(response){
       return  response.data;
   }).catch(function (error) {
       // handle error
       console.log(error);
       });
  return response;
}


async function getProvisions (){
    const response =
        await httpClient.get(GET_PROVISIONS_URL, {
        }).
   then(function(response){
       return  response.data;
   }).catch(function (error) {
       // handle error
       console.log(error);
       });
  return response;
}

async function getStatistics() {
    const response =
        await httpClient.get(GET_STATISTICS_URL, {}).then(function (response) {
            console.log(response.data.contents)
            return response.data.contents
        }).catch(function (error) {
            // handle error
            console.log(error);
        });
    return response;
}
async   function getUserSettings(){

    const  response= await httpClient.get(GET_USER_SETTINGS).then(function (response){
            return response.data
        }).catch(function (error){
            console.log(error)
        })
    return response;
}
async function getDefaultSettings(){
     const  response= await httpClient.get(GET_DEFAULT_SETTINGS).then(function (response){
            return response.data
        }).catch(function (error){
            console.log(error)
        })
    return response;
}

async function postChangePassword(currentPassword, newPassword)
{
    try {
        const response = await httpClient.post(CHANGE_PASSWORD_URL, {
            current_password: currentPassword,
            new_password: newPassword
        });
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
     } catch (error) {
        console.error(error);
        return false;
    }
}
async function updateUserDetails(request){
     try{
         const response=await httpClient.post(UPDATE_USER_DETAILS,request);
         return response;
     }catch(error){
         console.error(error);
     }
}
async function updateUserImageToS3(event) {
         try {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('image', file);
            formData.set('Content-Disposition', 'inline; filename="' + file.name + '"');
            const response = await httpClient.post(UPDATE_USER_IMAGE, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response;
     } catch (error) {
        console.error(error);
        return false;
    }

 }


export{
 getInfrastructure,
 getInfrastructureDetails,
 getSurveyLatestYear,
 getAvailableSurveyYears,
 getInfrastructureNext,
 getProvisions,
 getInfrastructure_StructureNumberInfo,
 getStatistics,
 postChangePassword,
 getUserSettings,
 getDefaultSettings,
 updateUserImageToS3,
 updateUserDetails
}