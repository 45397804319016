import States from '@/config/states'
import Counties from '@/config/county'
import {useStore} from "@/store/index";


export function getLookupValue(category, key) {
    const storeVar = useStore();
const applookup = storeVar.applookup;

    // Check if the given category exists in the AppLookup object
    if (!applookup[category]) {
      return key +' - No Description Available';
    }
  
    // Look up the value corresponding to the given key in the category
    const lookupItem = applookup[category].find(item => item.key === key);
    if (!lookupItem) {
      return key + ' - No Description Available';
    }
  
    // Return the value of the lookup item
    return lookupItem.value;
  }
  