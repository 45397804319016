<template>
  <v-card>
    <v-layout>
      <v-main>
        <v-app-bar prominent>
          <v-app-bar-title>Filters
          </v-app-bar-title>
          <template v-slot:append>
            <v-btn class="text-capitalize" @click="resetFilters">
              <v-icon start icon="mdi-arrow-u-left-top"></v-icon>
              Reset
            </v-btn>

          </template>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-text>
          <v-list dense nav>
            <v-autocomplete :items="lstStates"
                            :custom-filter="sortedStates(lstStates)"
                            item-title="name"
                            item-value="code"
                            label="State"
                            variant="solo"
                            v-model="selectedState"
                            @update:model-value="onChangeState"
                            data-test="states"
                            clearable
                            single-line
            >
            </v-autocomplete>
            <v-autocomplete :items="lstStateCounty"
                            :custom-filter="sortedFilters(lstStateCounty)"
                            item-title="name"
                            item-value="code"
                            label="County"
                            variant="solo"
                            v-model="selectedCounty"
                            @update:model-value="onChangeFilter"
                            data-test="stateCounty"
                            clearable
                            single-line
            >
            </v-autocomplete>
            <v-autocomplete :items="lstFilterLookup.rating"
                            :custom-filter="sortedFilters(lstFilterLookup.rating)"
                            item-title="value"
                            item-value="key"
                            label="Rating"
                            variant="solo"
                            v-model="selectedRating"
                            @update:model-value="onChangeFilter"
                            data-test="rating"
                            clearable
                            single-line
                            :menu-props="{maxHeight:200}"
            >
            </v-autocomplete>
            <v-autocomplete :items="lstFilterLookup.navigation_control"
                            :custom-filter="sortedFilters(lstFilterLookup.navigation_control)"
                            item-title="value"
                            item-value="key"
                            label="Navigation Control"
                            variant="solo"
                            v-model="selectedNavigationControl"
                            @update:model-value="onChangeFilter"
                            data-test="navigation_control"
                            clearable
                            single-line
            >
            </v-autocomplete>
            <v-autocomplete :items="lstFilterLookup.bridge_median"
                            :custom-filter="sortedFilters(lstFilterLookup.bridge_median)"
                            item-title="value"
                            item-value="key"
                            label="Bridge Median"
                            variant="solo"
                            v-model="selectedBridgeMedian"
                            @update:model-value="onChangeFilter"
                            data-test="bridge_median"
                            clearable
                            single-line
            >
            </v-autocomplete>
            <v-autocomplete :items="lstFilterLookup.structure_flared"
                            :custom-filter="sortedFilters(lstFilterLookup.structure_flared)"
                            item-title="value"
                            item-value="key"
                            label="Structure Flare"
                            variant="solo"
                            v-model="selectedStructureFlared"
                            @update:model-value="onChangeFilter"
                            data-test="structure_flared"
                            clearable
                            single-line
            >
            </v-autocomplete>
            <v-autocomplete :items="lstFilterLookup.type_of_service_on_bridge"
                            :custom-filter="sortedFilters(lstFilterLookup.type_of_service_on_bridge)"
                            item-title="value"
                            item-value="key"
                            label="Type of service on bridge"
                            variant="solo"
                            v-model="selectedTypeOfServiceOnBridge"
                            @update:model-value="onChangeFilter"
                            data-test="type_of_service_on_bridge"
                            clearable
                            single-line
            >
            </v-autocomplete>
            <v-autocomplete :items="lstFilterLookup.type_of_service_under_bridge"
                            :custom-filter="sortedFilters(lstFilterLookup.type_of_service_under_bridge)"
                            item-title="value"
                            item-value="key"
                            label="Type of service under bridge"
                            variant="solo"
                            v-model="selectedTypeOfServiceUnderBridge"
                            @update:model-value="onChangeFilter"
                            data-test="type_of_service_under_bridge"
                            clearable
                            single-line
            >
            </v-autocomplete>
            <v-autocomplete :items="provisions"
                            item-title="name"
                            item-value="id"                          
                            label="Provision Assets"
                            variant="solo"
                            clearable
                            single-line
                            data-test="provision"                            
                            v-model="selectedProvision"
                            @update:model-value="onChangeFilter"
            >
            </v-autocomplete>
         
          </v-list>
        </v-card-text>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
//Load static JSON data for the filters
import States from '@/config/states'
import Counties from '@/config/county'
import {useStore} from '@/store'
import {mapActions, mapState, mapWritableState} from 'pinia'

export default {
  name: 'Filter',
  setup() {

  },
  async created() {
    if (this.applookup != null)
      this.lstFilterLookup = this.applookup
    else {
      await this.fetchApplookup()
      this.lstFilterLookup = this.applookup
    }
  },
  mounted() {
    // load defaultState's counties
    this.lstStateCounty = this.getStateCounty(this.defaultStateCode);

  },
  data() {
    return {
      lstFilterLookup: [],
      lstStates: States,
      lstCounty: Counties,
      lstStateCounty: [],
      query_params: {},
    }
  },
  computed: {
    ...mapState(
      useStore,
       {
        latestSurveyYear : "latestSurveyYear",
        provisions : "provisions",
         defaultStateCode : "defaultStateCode",
         applookup: "applookup"

      }),
    ...mapWritableState(
        useStore, {
          selectedState: "selectedState",
          selectedCounty: "selectedCounty",
          selectedRating: "selectedRating",
          selectedNavigationControl: "selectedNavigationControl",
          selectedBridgeMedian: "selectedBridgeMedian",
          selectedStructureFlared: "selectedStructureFlared",
          selectedTypeOfServiceOnBridge: "selectedTypeOfServiceOnBridge",
          selectedTypeOfServiceUnderBridge: "selectedTypeOfServiceUnderBridge",
          selectedProvision: "selectedProvision",
          selectedYear: "selectedYear",
        }
    )


  },
  methods: {
    ...mapActions(useStore, 
    {
      setInfra: 'setLstInfrastructures',
      fetchApplookup:'fetchApplookup'
    }),
    getStateCounty(stateCode) {
      const counties = this.lstCounty.filter(
          function (item){
            return item.state === stateCode;
          }
      )
      if(counties){
        return counties[0].value;
      }
    },
    onChangeState(value) {
      if(value==null)
      {
        this.lstStateCounty = []
        this.selectedCounty = null
        this.selectedState = null
      }
      else {
        //load counties of the corresponding state
        this.lstStateCounty = this.getStateCounty(value)
        //We are concerned with the state code only to fetch the data from the API
        this.selectedState = {"name":"","code":value};
      }
      this.onChangeFilter()
    },
    async onChangeFilter() {
      this.query_params["state_code"] = this.selectedState == null ? '': this.selectedState.code
      this.query_params["county_code"] = this.selectedCounty == null ? '' : this.selectedCounty
      this.query_params["rating"] = this.selectedRating == null ? '' : this.selectedRating
      this.query_params["navigation_control"] = this.selectedNavigationControl == null ? '' : this.selectedNavigationControl
      this.query_params["bridge_median"] = this.selectedBridgeMedian == null ? '' : this.selectedBridgeMedian
      this.query_params["structure_flared"] = this.selectedStructureFlared == null ? '' : this.selectedStructureFlared
      this.query_params["type_of_service_on_bridge"] = this.selectedTypeOfServiceOnBridge == null ? '' : this.selectedTypeOfServiceOnBridge
      this.query_params["type_of_service_under_bridge"] = this.selectedTypeOfServiceUnderBridge == null ? '' : this.selectedTypeOfServiceUnderBridge
      this.query_params["year"] = this.selectedYear == null ? this.latestSurveyYear : this.selectedYear
      this.query_params["provision"] = this.selectedProvision == null ? '' : this.selectedProvision
      this.$emit('setOverlay')
      await this.setInfra(this.query_params);
      this.$emit('filterChanged')
    },
    sortedStates(states) {
      states.sort(function (x, y) {
        if (x.name > y.name) {
          return 1;
        }
        if (x.name < y.name) {
          return -1;
        }
        return 0;

      });
    },
    sortedFilters(filters) {
      if (filters){
      filters.sort(function (x, y) {
        if (x.value > y.value) {
          return 1;
        }
        if (x.value < y.value) {
          return -1;
        }
        return 0;
      });
      }
    },
    resetFilters()
    {
      this.selectedState = {"name": "", "code": this.defaultStateCode}
      this.lstStateCounty = this.getStateCounty(this.defaultStateCode)
      this.selectedRating = null
      this.selectedNavigationControl = null
      this.selectedBridgeMedian = null
      this.selectedStructureFlared = null
      this.selectedTypeOfServiceOnBridge = null
      this.selectedTypeOfServiceUnderBridge = null
      this.selectedYear = this.latestSurveyYear
      this.selectedProvision = null
      this.onChangeFilter()
    }
  }
}
</script>