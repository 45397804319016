import httpClient from '@/utils/request';
import {
    GET_APPLOOKUP_URL,
}
    from '@/config/apiconfig';

 async function getApplookup(parameters){
     const response =
         await httpClient.get(GET_APPLOOKUP_URL, {
             params: parameters,
         }).
    then(function(response){
        return  response.data;
    }).catch(function (error) {
        console.log(error);
        });
   return response;
}


export{
 getApplookup
}