<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
            label="Organization"
            v-model="selectedOrg"
            variant="solo"
            :items="lstOrgs"
            item-title="name"
            item-value="code"
            @update:model-value="onChangeOrg"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
            label="Users"
            :items="lstUsers"
            item-title="email"
            item-value="id"
            variant="solo"
            v-model="selectedUser"
            @update:model-value="onChangeUser"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete :items="lstStates"
                        item-title="name"
                        item-value="code"
                        label="State"
                        variant="solo"
                        v-model="selectedState"
                        @update:model-value="onChangeState"
                        data-test="states"
                        clearable
                        single-line
                        :menu-props="{maxHeight:200}"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete :items="lstStateCounty"
                        :custom-filter="sortedFilters(lstStateCounty)"
                        item-title="name"
                        item-value="code"
                        label="County"
                        variant="solo"
                        v-model="selectedCounty"
                        data-test="stateCounty"
                        clearable
                        single-line
                        @update:model-value="onChangeCounty"
                        :menu-props="{maxHeight:200}"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete :items="lstFilterLookup.navigation_control"
                        :custom-filter="sortedFilters(lstFilterLookup.navigation_control)"
                        item-title="value"
                        item-value="key"
                        label="Navigation Control"
                        variant="solo"
                        v-model="selectedNavigationControl"
                        data-test="navigation_control"
                        clearable
                        single-line
                        :menu-props="{maxHeight:200}"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="4" justify="end">
        <v-card>
          <v-toolbar-title style="color: gray; font: 16px">Unassigned</v-toolbar-title>
          <v-text-field
              clearable
              label="Search Unassigned Infrastructures"
              v-model="searchUnassigned"
              persistent-clear
              v-on:keyup.enter.prevent="searchUnassignedInfrastructures"
              append-inner-icon="mdi-magnify"
              @click:clear="clearSearchUnassigned"
              @click:append-inner="searchUnassignedInfrastructures"
          ></v-text-field>
          <div class="list-container">
            <v-list density="compact" :lines="false" nav>
              <v-list-item
                  v-for="item in lstUnassigned"
                  :key="item.id">
                <v-list-item-content>
                  {{ item.structure_number }}
                </v-list-item-content>
                <template v-slot:prepend>
                  <v-list-item-action start>
                    <v-checkbox-btn :model-value="item.selected" @change="toggleSelection(item)"></v-checkbox-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-col>
      <v-col align="center">
        <div class="div-row">
          <v-btn
              @click="filterInfrastructures">
            Filter
          </v-btn>
        </div>
        <br>
        <div class="divBtn">
          <v-btn @click="assignInfrastructures" style="width:10%"> &gt;&gt;</v-btn>
          <br>
          <v-btn @click="unassignInfrastructures" style="width:10%"> &lt;&lt;</v-btn>
        </div>
        <div class="divBtn">
          <br>
          <br>
          <v-btn @click="saveInfrastructures">SAVE</v-btn>
          <v-snackbar
              v-model="snackbarCustom.show"
              :color="snackbarCustom.color"
              location="top">
            {{ snackbarCustom.message }}
            <template v-slot:actions>
              <v-btn color="blue" variant="text" @click="snackbarCustom.show = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-toolbar-title style="color: gray; font: 16px">Assigned</v-toolbar-title>
          <v-text-field
              clearable
              label="Search Assigned Infrastructures"
              v-model="searchAssigned"
              persistent-clear
              v-on:keyup.enter.prevent="searchAssignedInfrastructures"
              append-inner-icon="mdi-magnify"
              @click:clear="clearSearchAssigned"
              @click:append-inner="searchAssignedInfrastructures"
          ></v-text-field>
          <div class="list-container">
            <v-list density="compact" :lines="false" nav>
              <v-list-item
                  v-for="item in lstAssigned"
                  :key="item.id">
                <v-list-item-content>
                  {{ item.structure_number }}
                </v-list-item-content>
                <template v-slot:prepend>
                  <v-list-item-action start>
                    <v-checkbox-btn :model-value="item.selected" @change="toggleSelection(item)"></v-checkbox-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useStore} from "@/store";
import {getAllOrg, postUserInfrastructure} from '@/api/assignbridge.api'
import {getInfrastructuresForUser, deleteUserInfrastructure} from '@/api/userinfrastructure.api'
import {get_saved_data_from_OrgInfra} from "@/api/org_infra.api"
import States from "@/config/states";
import Counties from "@/config/county";
import {getInfrastructure_StructureNumberInfo} from "@/api/infrastructure.api";

export default {
  name: "AssignUserInfrastructure",
  data() {
    return {
      lstFilterLookup: [],
      lstAllUsers: [],
      lstUsers: [],
      lstOrgs: [],
      selectedUser: null,
      selectedOrg: null,
      selectedState: null,
      selectedCounty: null,
      selectedNavigationControl: null,
      lstStates: States,
      lstCounty: Counties,
      lstStateCounty: [],
      lstAssigned: [],
      lstOrgAssigned: [],
      lstUnassigned: [],
      lstOrgUnassigned: [],
      selectedInfraToAssign: [],
      selectedInfraToUnassign: [],
      selectedInfras: [],
      unselectedInfras: [],
      searchUnassigned: '',
      searchAssigned: '',
      snackbarCustom: {
        show: false,
        message: '',
        timeout: 1000,
        color: 'warning' // Change the color as needed (e.g., 'success', 'error', 'warning', 'info')
      },
      searchAssignModeOn: false,
      searchUnassignModeOn: false,
    }
  },
  async mounted() {
    let userInfo = await this.fetchUsers()
    this.lstAllUsers = userInfo.results
    let orgsInfo = await getAllOrg()
    this.lstOrgs = orgsInfo
  },
  created(){
        this.lstFilterLookup = this.applookup
  },
  methods: {
    ...mapActions(useStore, {fetchUsers: 'fetchUsers', fetchBridges: 'fetchBridges'}),
    async onChangeUser(value) {
      let query_params = {
        email: this.selectedUser,
        id: value
      };
      this.lstAssigned = await getInfrastructuresForUser(query_params)
      this.lstOrgAssigned = this.lstAssigned
      this.lstUnassigned = []
    },
    toggleSelection(item) {
      item.selected = !item.selected;
    },
    onChangeCounty(selecteObj) {
      this.lstUnassigned = []
    },
    async onChangeOrg(value) {
      this.lstUsers = this.getUsersForOrg(value)
      this.selectedUser = null;
      this.lstAssigned = [];
      this.lstUnassigned = [];
    },
    getUsersForOrg(orgs_id) {
      const filteredUsers = this.lstAllUsers.filter(item => item.orgs_id === orgs_id);
      const users = Array.prototype.slice.call(filteredUsers);
      return users
    },
    onChangeState(value) {
      if (value == null) {
        this.lstStateCounty = []
        this.selectedCounty = null
        this.selectedState = null
      } else {
        //load counties of the corresponding state
        this.lstStateCounty = this.getStateCounty(value)
      }
      this.lstUnassigned = []
    },
    sortedFilters(filters) {
      filters.sort(function (x, y) {
        if (x.value > y.value) {
          return 1;
        }
        if (x.value < y.value) {
          return -1;
        }
        return 0;
      });
    },

    async filterInfrastructures() {
      if (this.selectedOrg == null || this.selectedUser == null) {
        this.snackbarCustom.show = true
        this.snackbarCustom.message = 'Organisation and User should be selected'
        this.snackbarCustom.color = "warning"
        return
      }
      let query_params_orginfra = {
        state_code: this.selectedState,
        county_code: this.selectedCounty,
        navigation_control: this.selectedNavigationControl,
        org_Id: this.selectedOrg
      };

      let previous_saved_info = await get_saved_data_from_OrgInfra(query_params_orginfra);
      let infra_response = await getInfrastructure_StructureNumberInfo(query_params_orginfra);

      if (previous_saved_info.length > 0) {
        let idsToRemove = new Set(previous_saved_info);
        infra_response = infra_response.filter(obj => {
          if (idsToRemove.has(obj.id)) {
            return true; 
          }
          return false;
        }).map(({structure_number, id}) => ({structure_number, id}));
        this.lstUnassigned = infra_response
        this.lstUnassigned = this.lstUnassigned.filter(item => !this.lstAssigned.some(element => element.id === item.id));
        this.lstOrgUnassigned = this.lstUnassigned //retain a copy of the original list. This would be used in the workflows involving search operations
      }
    },
    assignInfrastructures() {
      this.selectedInfraToAssign = this.lstUnassigned.filter(item => item.selected == true);

      for (let i = 0; i < this.selectedInfraToAssign.length; i++) {
        this.lstAssigned = this.lstAssigned.concat(this.getStructureFromUnassignedInfra(this.selectedInfraToAssign[i]))
      }

      let toRemove = this.selectedInfraToAssign

      this.lstUnassigned = this.lstUnassigned.filter(item => !toRemove.some(element => element.id === item.id));

      this.selectedInfras = this.selectedInfraToAssign.map(item => item.id);

      this.selectedInfraToUnassign = []
    },
    unassignInfrastructures() {
      this.selectedInfraToUnassign = this.lstAssigned.filter(item => item.selected == true);

      for (let i = 0; i < this.selectedInfraToUnassign.length; i++) {
        this.lstUnassigned = this.lstUnassigned.concat(this.getStructureFromAssignedInfra(this.selectedInfraToUnassign[i]))
      }

      const toRemove = this.selectedInfraToUnassign

      this.lstAssigned = this.lstAssigned.filter(item => !toRemove.some(element => element.id === item.id));

      this.unselectedInfras = this.selectedInfraToUnassign.map(item => item.id);
    },
    saveCleanUp() {
      this.selectedInfras = []
      this.unselectedInfras = []
      this.lstAssigned.forEach(item => {
        if ('selected' in item) {
          item.selected = false;
        }
      });
      this.lstUnassigned.forEach(item => {
        if ('selected' in item) {
          item.selected = false;
        }
      });
    },
    async saveInfrastructures() {
      let saveStatus = 0
      let deleteStatus = 0
      const OUTCOME = {
        SUCCESS: 1,
        FAILURE: 2,
      }
      if (this.selectedInfras.length == 0 && this.unselectedInfras.length == 0) {
        this.snackbarCustom.color = 'warning'
        this.snackbarCustom.message = 'No infrastructure selected'
        this.snackbarCustom.show = true
        return
      }

      if (this.areArraysOfObjectsEqual(this.lstAssigned, this.lstOrgAssigned) && this.areArraysOfObjectsEqual(this.lstUnassigned, this.lstOrgUnassigned)) {
        this.snackbarCustom.message = 'No change detected'
        this.snackbarCustom.show = true
        this.snackbarCustom.color = 'warning'
        this.saveCleanUp()
        return
      }

      if (this.selectedInfras.length) {
        const response = await postUserInfrastructure({
          user_id: this.selectedUser,
          structure_number: this.selectedInfras
        })

        if (response.status == 201 || response.status == 200) {
          saveStatus = OUTCOME.SUCCESS

          // on successful response, the lstOrgUnassigned and lstOrgAssigned list should be updated
          if (!this.searchUnassignModeOn) {
            this.lstOrgUnassigned = this.lstUnassigned
            this.lstOrgAssigned = this.lstAssigned
          } else {
            this.lstOrgUnassigned = this.lstOrgUnassigned.filter(item => !this.selectedInfraToAssign.some(element => element.id === item.id));
            this.lstOrgAssigned = this.lstAssigned
          }

        } else {
          saveStatus = OUTCOME.ERROR
        }
      }
      if (this.unselectedInfras.length) {
        const response = await deleteUserInfrastructure({
          user_id: this.selectedUser,
          structure_number: this.unselectedInfras
        })
        if (response.status == 204) {
          deleteStatus = OUTCOME.SUCCESS

          // on successful response, the lstOrgUnassigned and lstOrgAssigned list should be updated
          if (!this.searchAssignModeOn) {
            this.lstOrgAssigned = this.lstAssigned
            this.lstOrgUnassigned = this.lstUnassigned
          }
          else {
            this.lstOrgAssigned = this.lstOrgAssigned.filter(item => !this.selectedInfraToUnassign.some(element => element.id === item.id));
            this.lstOrgUnassigned = this.lstUnassigned
          }
        } else {
          deleteStatus = OUTCOME.ERROR
        }
      }
      if (saveStatus === OUTCOME.SUCCESS && deleteStatus === OUTCOME.SUCCESS) {
        this.snackbarCustom.color = 'success'
        this.snackbarCustom.message = 'Changes made successfully!'
        console.log("Both save and delete operations were successful.");
      } else if (saveStatus === OUTCOME.ERROR) {
        this.snackbarCustom.color = 'error'
        this.snackbarCustom.message = 'Error occurred while saving!'
        console.log("Save operation resulted in an error.");
      } else if (deleteStatus === OUTCOME.ERROR) {
        this.snackbarCustom.color = 'error'
        this.snackbarCustom.message = 'Error occurred while deleting!'
        console.log("Delete operation resulted in an error.");
      } else if (saveStatus === OUTCOME.SUCCESS) {
        this.snackbarCustom.color = 'success'
        this.snackbarCustom.message = 'Saved successfully!'
        console.log("Save operation was successful.");
      } else if (deleteStatus === OUTCOME.SUCCESS) {
        this.snackbarCustom.color = 'success'
        this.snackbarCustom.message = 'Deleted successfully!'
        console.log("Delete operation was successful.");
      }
      this.snackbarCustom.show = true
      this.saveCleanUp()
    },
    getStructureFromUnassignedInfra(infrastructure) {
      const structure = this.lstUnassigned.filter(
          function (item) {
            return item.id === infrastructure.id;
          }
      )
      return structure
    },
    getStructureFromAssignedInfra(infrastructure) {
      const structure = this.lstAssigned.filter(
          function (item) {
            return item.id === infrastructure.id;
          }
      )
      return structure
    },
    getStateCounty(stateCode) {
      const counties = this.lstCounty.filter(
          function (item) {
            return item.state === stateCode;
          }
      )
      if (counties) {
        return counties[0].value;
      }
    },
    areArraysOfObjectsEqual(A, B) {
      if (A.length !== B.length) {
        return false;
      }
      
      const setA = new Set(A.map(obj => JSON.stringify(obj)));
      const setB = new Set(B.map(obj => JSON.stringify(obj)));
      
      if (setA.size !== setB.size) {
        return false;
      }

      for (const strObject of setA) {
        if (!setB.has(strObject)) {
          return false;
        }
      }
      return true;
    },
    searchInfrastructures(searchIn, searchInfra) {
      let matchingInfrastructures = searchIn.filter((item) => {
        return (
            item.structure_number.toLowerCase().includes(searchInfra.toLowerCase())
        );
      });
      return matchingInfrastructures
    },

    searchUnassignedInfrastructures() {
      let matchingInfrastructures = this.searchInfrastructures(this.lstUnassigned, this.searchUnassigned)
      this.lstUnassigned = matchingInfrastructures
      this.searchUnassignModeOn = true
    },
    clearSearchUnassigned() {
      this.lstUnassigned = this.lstOrgUnassigned
      this.lstUnassigned = this.lstUnassigned.filter(item => !this.selectedInfraToAssign.some(element => element.id === item.id));
      this.searchUnassignModeOn = false

    },
    searchAssignedInfrastructures() {
      this.searchAssignModeOn = true
      let matchingInfrastructures = this.searchInfrastructures(this.lstAssigned, this.searchAssigned)
      this.lstAssigned = matchingInfrastructures
    },
    clearSearchAssigned() {
      this.lstAssigned = this.lstOrgAssigned
      this.searchAssignModeOn = false
      this.lstAssigned = this.lstAssigned.filter(item => !this.selectedInfraToUnassign.some(element => element.id === item.id));

    },
  },
  computed: {
    ...mapState(useStore, ['applookup']),
  },
}
</script>

<style scoped>

.list-container {
  max-height: 200px; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
}

</style>