<script>
import {useStore} from "@/store";
export default {
  name: 'RefreshHome',
  mounted() {
    const store = useStore();
    store.setRefreshed(true);
    this.$router.push({ name: 'Home'});
  }
}
</script>

<template>
  <div></div>
</template>

<style scoped>

</style>