<template>
<div class="container">
  <section>
    <div class="card" style="height: 600px; width: 1200px">
      <div class="card-info">
        <h1 style="font-size: 38px;">{{contents.title}}</h1>
        <p id="card-info-desc">{{contents.intro}}</p>
        <p id="card-info-desc" v-html="contents.body"></p>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import axios from "axios";
import { TERMS_PAGE_URL } from '@/config/apiconfig';


export default {
  name: "TermsAndConditions",
    data() {
    return{
      contents: {},
    }
  },
  methods: {
   loadContents: function() {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.common['Content-Type'] ='application/json';
      axios({
            method:'get',
            url: TERMS_PAGE_URL,
        }).then(
        response => {
          this.contents = response.data;
        }
      );
    },
   },
  mounted() {
    this.loadContents();
    },
}
</script>

<style scoped>
header {
  padding: 2em 1em;
  display: grid;
  align-items: center;
  margin: -50px;
  padding-right: 0px;
  margin-bottom: -140px;
  margin-top: 95px;
}
header h1 {
  font-family: serif;
  color: var(--dark-green);
}

section {
  width: 100%;
  padding: 0.2em;
}

section h4 {
  opacity: 0.8;
}


.card {
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  background-color: var(--white);
  border: 0px solid rgba(0,0,0,.125)
}

.card-info {
  max-height: 600px;
  text-align: justify;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  padding: 1em 2em;
  justify-items: center;
}
.card-info h1 {
  font-family: "Allura", serif;
  font-size: 5rem;
  color: var(--dark-green);
  align-self: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
.card-info p {
  font-size: 1.2rem;
  align-self: center;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  line-height: 22px;
  font-family: Times New Roman;
}
.card-info a {
  grid-template-columns: max-content 20px;
  align-items: center;
  column-gap: 7px;
  color: var(--dark-green);
  font-size: 0.85rem;
  font-weight: bold;
  align-self: center;
  transform: translateX(0px);
  transition: transform 0.3s 0.1s ease-out;
}




@media (max-width: 400px) {
  .card {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .card-info {
    order: 2;
    height: 400px;
  }

}

@media (max-width: 400px) {
  header {
    padding: 1em;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
  .card-info h1 {
    font-size: 3rem;
  }
}

.container{
  width: 100%;
  }
</style>