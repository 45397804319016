import httpClient from '@/utils/request';
import {GENERATE_TOKEN_URL,GET_ADMIN_URL} from '@/config/apiconfig';

 async function getToken (payload){
  
   return await httpClient.post(GENERATE_TOKEN_URL, payload)
    .then((response)=>{
        return response.data;
      })
    .catch((error)=>{
        
      });
}
async function is_admin(){
     return await httpClient.get(GET_ADMIN_URL).then((response)=>{
         return response.data;
     })
}

export{
    getToken,
    is_admin

}