import { createWebHistory, createRouter } from "vue-router";
import Home from '@/components/Home';
import MapDashboard from '@/components/MapDashboard.vue';
import AssignOrgInfrastructure from '@/components/AssignOrgInfrastructure.vue'
import AssignBridge from '@/components/AssignBridge';
import TermsAndConditions from "@/components/TermsAndConditions";
import Analytics from "@/components/Analytics"
import AssignUserInfrastructure from "@/components/AssignUserInfrastructure.vue"
import NIAAnalytics from "@/components/NIAAnalytics.vue"
import ChangePassword from "@/components/ChangePassword.vue"
import ViewProfile from "@/components/ViewProfile.vue";
import RefreshHome from "@/components/RefreshHome.vue";
import { useStore } from '@/store'

function guardRoute(to, from, next) {
    const store = useStore()
    var isAuthenticated = store.getIsAuthenticated;
    if (isAuthenticated) {
        next(); // allow to enter route
    } else {
        next('/'); // go to 'Home';
    }
}

const routes = [
    {path: '/', component: Home, name: "Home"},
    {path: '/MapDashboard', component: MapDashboard, name: "MapDashboard", beforeEnter: guardRoute},
    {path: '/Assign', component: AssignBridge, name: "Assign", beforeEnter: guardRoute},
    {path: '/AssignOrgInfrastructure', component: AssignOrgInfrastructure, name: "AssignOrgInfrastructure", beforeEnter: guardRoute},
    {path: '/terms-and-conditions', component: TermsAndConditions, name: "TermsAndConditions"},
    {path: '/Analytics/:provisionsName', component: Analytics, props: route => ({
    name: route.params.provisionsName,
    url: route.query.provisionsUrl
  }), name: "Analytics", beforeEnter: guardRoute},
    {path: '/AssignUserInfrastructure', component: AssignUserInfrastructure, name: "AssignUserInfrastructure", beforeEnter: guardRoute},
    {path: '/NIAAnalytics', component: NIAAnalytics, name: "NIAAnalytics", beforeEnter: guardRoute},
    {path: '/ChangePassword', component: ChangePassword, name: "ChangePassword", beforeEnter: guardRoute},
    {path: '/ViewProfile', component: ViewProfile, name:'ViewProfile', beforeEnter: guardRoute},
    {path: '/refresh-home', name: 'RefreshHome', component: RefreshHome, beforeEnter: guardRoute},
]
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
export default router;