<template>
  <v-toolbar class="bg-black px-5" style="border-bottom: 2px solid #e22626;" app>
    <a class="navbar-brand" href="/">
      <img class="img nav-custom-img" src="../assets/nia_logo_white.png"
           alt="NIA Logo">
    </a>
    <v-btn class="ma-2" icon @click="toggleTheme">
      <v-icon>
        {{ theme.global.current.value.dark ? 'mdi-weather-night': 'mdi-weather-sunny'}}
      </v-icon>
    </v-btn>
    <v-spacer></v-spacer>

    <v-container class="d-flex justify-end align-center pa-0" v-if=!(this.getIsAuthenticated)>
      <v-btn class="hidden-sm-and-down text-capitalize"
             data-cy="#home"
             prepend-icon="mdi-home"
             to="/">
        Home
      </v-btn>
      <v-btn class="hidden-sm-and-down text-capitalize"
             data-cy="#api-documentation"
             prepend-icon="mdi-file-document"
             :href="api_documentation_url"
             target="_blank">
        API Doc
      </v-btn>
      <v-btn flat class="hidden-xs-and-down text-capitalize"
             data-cy="#login"
             prepend-icon="mdi-login">
        Log In
        <Login :current-theme = "currentTheme"/>
      </v-btn>
    </v-container>

    <v-container class="d-flex justify-end align-center pa-0" v-if=(this.getIsAuthenticated)>
      <v-btn flat class="hidden-sm-and-down text-capitalize"
             data-cy="#home"
             prepend-icon="mdi-home"
             to="/">
        Home
      </v-btn>
      <v-btn class="hidden-sm-and-down text-capitalize"
             data-cy="#api-documentation"
             prepend-icon="mdi-file-document"
             :href="api_documentation_url"
             target="_blank">
        API Doc
      </v-btn>
      <v-btn flat class="hidden-xs-and-down text-capitalize"
             prepend-icon="mdi-view-dashboard"
             data-cy="#mapdashboard"
             to="/MapDashboard">
        Dashboard
      </v-btn>

      <v-menu class="hidden-xs-and-down"  v-if=(this.getIsAdmin)>
        <template v-slot:activator="{ props }">
          <v-btn flat class="hidden-xs-and-down text-capitalize"
                 prepend-icon="mdi-account-cog"
                 data-cy="#admin"
                 v-bind="props">
            Admin
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in adminLookup"
                       :key="item.key"
                       :title="item.key"
                       :target=targetValue(item.open_in_new_tab)
                       :href=sourceadminURL(item.key)
                       v-show="item.admin_menu">
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu class="hidden-xs-and-down">
        <template v-slot:activator="{ props }">
          <v-btn flat class="hidden-xs-and-down text-capitalize"
                 prepend-icon="mdi-tools"
                 data-cy="#analytics"
                 v-bind="props">
            Analytics
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in analyticsLookup"
                       :key="item.key"
                       :title="item.key"
                       :target=targetValue(item.open_in_new_tab)
                       :href=sourceURL(item.key)>
          </v-list-item>
          <v-list-item href="/NIAAnalytics">
            <v-list-item-title> NIA Analytics </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn flat v-bind="props" color="grey">
            <span>
              <img class="img nav-custom-img" :src="userAvatarPath || require('@/assets/user_icon.png')" alt="Avatar">
            </span>
            <v-icon left>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title> Signed in as <strong v-html="this.getEmail"></strong></v-list-item-title>
          </v-list-item>
          <v-list-item href="/AssignOrgInfrastructure">
            <v-list-item-title>Assign Infrastructure to Organisation</v-list-item-title>
          </v-list-item>
          <v-list-item href="/AssignUserInfrastructure">
            <v-list-item-title> Assign Infrastructures to Users </v-list-item-title>
          </v-list-item>
          <v-list-item href="/ViewProfile">
            <v-list-item-title> View Profile</v-list-item-title>
          </v-list-item>
          <v-list-item href="/ChangePassword">
            <v-list-item-title> Change Password</v-list-item-title>
          </v-list-item>
          <v-list-item href="/" @click="logout">
            <v-list-item-title> Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-container>
  </v-toolbar>
</template>

<script>
import {useStore, storeToRefs} from '@/store'
import {mapActions, mapState} from 'pinia'
import {ref} from 'vue'
import Login from './Login.vue'
import Analytics from "@/components/Analytics";
import { useTheme } from 'vuetify'
import { useThemeSwitcher } from '@/store/theme';


export default {
  name: 'Header',
  components: {Analytics, Login},
  setup() {
    const {theme,toggleTheme,currentTheme}=useThemeSwitcher();
    return {
      Login,
      theme,
      toggleTheme,
      currentTheme,
    }
  },
  methods: {
    ...mapActions(useStore, {logout: 'logOut'}),
    targetValue(open_in_new_tab)
    {
      return open_in_new_tab?"__blank":"";
    },
    sourceURL(key)
    {
      let sourceDetails = this.analyticsLookup.filter(
          function (item) {
            return item.key === key;
          }
      )
      if(sourceDetails[0])
        return sourceDetails[0].iframe ? this.src+key : sourceDetails[0].url;
      return null
    },
        sourceadminURL(key)
    {
      let sourceDetails = this.adminLookup.filter(
          function (item) {
            return item.key === key;
          }
      )

      if(sourceDetails[0])
        return sourceDetails[0].iframe ? sourceDetails[0].url: this.src+key ;
      return null
    },
        async setApplookup() {
      this.analyticsLookup = this.applookup.analytics_applications;
      this.adminLookup = this.applookup.admin_options;
    },
    resetApplookup() {
      this.analyticsLookup = [];
      this.adminLookup = [];
    },
  },
  computed: {
    ...mapState(useStore, ['getIsAuthenticated', 'getEmail','getIsAdmin','applookup','userAvatarPath']),
  },
  watch: {
    applookup: {
      immediate: true,
      handler(newStatus) {
        if (newStatus) {
          this.setApplookup();
        } else {
          this.resetApplookup();
        }
      },
    },
  },
  data() {
    return {
      src: '/Analytics/',
      api_documentation_url: process.env.VUE_APP_API_DOCUMENTATION,
      analyticsLookup:null,
      adminLookup:null
    }
  },
}
</script>

<style>
.nav-custom-img {
  width: 40px;
  position: relative;
}

.navbar {
  padding: 0.1rem 1rem;
  position: fixed;
  width: 100%;
  align-items: center;
  display: flex;
}

.navbar-expand-lg {
  flex-flow: nowrap;
}

.navbar-brand {
  color: White;
  font-family: 'Arial Narrow', sans-serif;
  font-size: 1.1em;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none !important;

  line-height: inherit;
}

#uno {
  font-size: 0.9em;
  text-transform: uppercase;
  font-family: 'Arial Narrow';
  color: #FFF;
  display: inline-block;
  vertical-align: middle;

  margin-bottom: 0;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none

}

.img {
  vertical-align: middle;
  border-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: white;
  text-align: right;

}

.nav-item {
  text-align: right;

}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.navbar-collapse {
  flex-grow: 1;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

#button {
  background-color: black;
  border-width: 0;
  color: #FFFFFF;
}

a {
  text-decoration: none;
  margin: 5px;
}
</style>