import axios from "axios";
import { API_URL } from "@/config/apiconfig";
import {useStore} from "@/store";


const httpClient = axios.create({
    baseUrl: API_URL,
    // timeout: 1000,
    headers: {
        "Content-Type": "application/json",
    }
});

//add the token to headers after login and use it for subsequent requests
const getAuthToken = () => sessionStorage.getItem('token') == null ? null : "Bearer "+ JSON.parse(sessionStorage.getItem('token')).access;

const authInterceptor = (config) => {
    if(sessionStorage.getItem('token')!=null)
    {      
    const authStore = useStore();
    authStore.inspectToken();
    config.headers['Authorization'] = getAuthToken();
    }
    return config;
}
httpClient.interceptors.request.use(authInterceptor);


export default httpClient;