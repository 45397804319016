import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from '@/router'

import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components' 
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'


const pinia = createPinia()
const vuetify = createVuetify({
    components,
    directives,
    theme: {
        dark: localStorage.getItem('theme') === 'dark',
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      }
    },
  })


const app = createApp(App)

app.use(pinia);
app.use(router);
app.use(vuetify);
app.mount('#app');

export { vuetify }