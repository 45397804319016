<template>
 <v-card class="custom-card">
    <p style="margin-bottom:0.5rem">
    <v-car-text class="font-weight-black"> Structure Number : </v-car-text>
    <v-car-text >{{ selectedInfrastructure.structure_number }}</v-car-text>
 
    <v-car-text class="font-weight-black"> Year Built : </v-car-text>
    <v-car-text >{{ selectedInfrastructure.year_built }}</v-car-text>
</p>
    <v-tabs v-model="activeTab"    class="custom-tabs" >
        <v-tab   v-for="(tab, index) in tabs" :key="index" @click="handleTabClick(tab)" :class="{ 'custom-active-tab': activeTab === tab }"  > {{tab }}</v-tab>
      </v-tabs>    
     
        <v-window  v-model="activeTab" >
        <v-window-item v-for="(tab, index) in tabs" :key="index" :value="tab" 
        >
        <v-card v-if="tab==='Meta'">           
      <v-container class="max-height-container">
            <v-row>
              <v-col>
                <v-label class="font-weight-black">Deck Protection</v-label>
              </v-col>
              <v-col>
                {{getLookupValue("deck_protection", selectedInfrastructure.deck_protection) }}
              </v-col>
            </v-row> 
            <v-row>
              <v-col>
                <v-label class="font-weight-black">Highway Agency District</v-label>
              </v-col>
              <v-col>
                {{ selectedInfrastructure.highway_agency_district }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-label class="font-weight-black">Federal Agency Indicator</v-label>
              </v-col>
              <v-col>
                {{ selectedInfrastructure.federal_agency_indicator }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-label class="font-weight-black">Inventory Route Number</v-label>
              </v-col>
              <v-col>
                {{ selectedInfrastructure.inventory_route_number }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-label class="font-weight-black">Direction of Traffic</v-label>
              </v-col>
              <v-col>
                {{ getLookupValue("direction_of_traffic",selectedInfrastructure.direction_of_traffic) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-label class="font-weight-black">Features Intersected</v-label>
              </v-col>
              <v-col>
                {{ selectedInfrastructure.features_intersected }}
              </v-col>
            </v-row>
            
            <v-row>
              <v-col>
                <v-label class="font-weight-black">Latitude</v-label>
              </v-col>
              <v-col>
                {{ Number(selectedInfrastructure.latitude).toFixed(7) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-label class="font-weight-black">Longitude</v-label>
              </v-col>
              <v-col>
                {{ Number(selectedInfrastructure.longitude).toFixed(7) }}
              </v-col>
            </v-row>
          </v-container> 
       
        </v-card>
        <v-card v-if="tab === 'Survey'">
        
        <v-container class="max-height-container">
          
          <v-row>
            
            <v-col>
              <v-label class="font-weight-black"> Inspection Frequency</v-label>
            </v-col>
            <v-col>   {{ selectedInfrastructure.survey[0].designatedInspectionFrequency }}</v-col>
          </v-row> 
          <v-row>
            
              <v-col>
                <v-label class="font-weight-black"> Avergae Daily Traffic</v-label>
              </v-col>
              <v-col>   {{ selectedInfrastructure.survey[0].average_daily_traffic }}</v-col>
            </v-row> 
            <v-row>
              <v-col>
                <v-label class="font-weight-black"> Avergae Daily Truck Traffic</v-label>
              </v-col>
              <v-col>   {{ selectedInfrastructure.survey[0].average_daily_truck_traffic }}</v-col>
            </v-row> 
            <v-row>
              <v-col>
                <v-label class="font-weight-black"> Deck Rating</v-label>
              </v-col>
              <v-col>   {{ this.getLookupValue("rating", selectedInfrastructure.survey[0].deck_rating) }}</v-col>
            </v-row>   
            <v-row>
              <v-col>
                <v-label class="font-weight-black"> Operating Rating</v-label>
              </v-col>
              <v-col>   {{ selectedInfrastructure.survey[0].operating_rating }}</v-col>
            </v-row> 
            <v-row>
              <v-col>
                <v-label class="font-weight-black"> Sufficiency Rating</v-label>
              </v-col>
              <v-col>   {{ selectedInfrastructure.survey[0].sufficiency_rating }}</v-col>
            </v-row>   
            <v-row>
              <v-col>
                <v-label class="font-weight-black"> Year of Improvement Cost</v-label>
              </v-col>
              <v-col>   {{ selectedInfrastructure.survey[0].year_of_improvement_cost }}</v-col>
            </v-row>   
            <v-row>
              <v-col>
                <v-label class="font-weight-black"> Improvement Cost</v-label>
              </v-col>
              <v-col>   {{ selectedInfrastructure.survey[0].bridge_improvement_cost }}</v-col>
            </v-row>   
            
          </v-container>
        </v-card>
        <v-card v-if="tab === 'Elements'">
         
        <v-container class="max-height-container">
          <template v-if="selectedInfrastructure.elements.length">
          <v-row>
            <v-col>
              Number
            </v-col>
            <v-col>
              TotalQuantity
            </v-col>
            <v-col>
              Condition 1
            </v-col>
            <v-col>
              Condition 2
            </v-col>
            <v-col>
              Condition 3
            </v-col>
            <v-col>
              Condition 4
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in selectedInfrastructure.elements " :key="index">
          
              <v-col>
                {{item.number}}
              </v-col>
              <v-col>
                {{item.total_quantity}}
              </v-col>
              <v-col>
                {{item.condition_one}}
              </v-col>  
              <v-col>
                {{item.condition_two}}
              </v-col>
              <v-col>
                {{item.condition_three}}
              </v-col>
       
              <v-col>
                {{item.condition_four}}
              </v-col>
              </v-row>
            </template> 
            <template v-else>
              <v-label class="font-weight-black"> No Elements </v-label>
              </template>
            
          </v-container>
        </v-card>
        <v-card v-if="tab === 'Provision'">
          <v-container class="max-height-container">
            <template v-if="selectedInfrastructure.provisions.length">
            <v-row  v-for="(item, index) in provisionProperties " :key="index">
              <v-col>
                <v-label class="font-weight-black"> </v-label>
                <a v-on:click="handleProvisionsClick(item)" class="url-link" target="_blank">{{ item.name }}</a>
               </v-col>
            </v-row>  
            </template> 
            <template v-else>
              <v-label class="font-weight-black"> No Provisions </v-label>
              </template>
          </v-container>
        </v-card>
      </v-window-item>
    </v-window>
  </v-card>
</template>
<script>



import {mapState} from "pinia";
import {useStore} from "@/store";
import router from '@/router'
export default {
    name: 'InfrastructureDetails',  
    data(){
      return {
      activeTab: 'Meta',      
      tabs: ['Meta','Survey', 'Elements', 'Provision'],
        src:'/Analytics/',
        provisionProperties:[{}],
    }
    },  
    methods: {
    handleTabClick(selectedTab) {
      this.activeTab= selectedTab;
      this.handleClick();
    },
 constructURLWithQueryParams(baseURL, queryParams) {
  var url = baseURL + '?';

  for (var i = 0; i < queryParams.length; i++) {
    var param = queryParams[i];
    var param_name = param.parm_name
    var param_value = param.parm_value
    if (param.parm_value.startsWith('infrastructure.')) {
    var parts = param.parm_value.split('.');
    var property = parts[1];
    var infra = this.selectedInfrastructure
    if (Object.prototype.hasOwnProperty.call(this.selectedInfrastructure, property)) {
      param_value = this.selectedInfrastructure[property];
    }
    }
    url += encodeURIComponent(param_name) + '=' + encodeURIComponent(param_value);
    if (i < queryParams.length - 1) {
      url += '&';
    }
  }
  return url;
},
      constructURLWithRequestParams(baseURL, requestParams) {
  var url = baseURL + '/';
    var param = requestParams;
    var param_name = param.resource_model
    var param_value = param.parm_value
    if (param.parm_value.startsWith('infrastructure.')) {
    var parts = param.parm_value.split('.');
    var property = parts[1];
    var infra = this.selectedInfrastructure
    if (Object.prototype.hasOwnProperty.call(this.selectedInfrastructure, property)) {
      param_value = this.selectedInfrastructure[property];
    }
  }
        url += encodeURIComponent(param_name) + '/' + encodeURIComponent(param_value);

        return  url
},
      handleProvisionsClick(item){
      let url = item.url
        if (Object.prototype.hasOwnProperty.call(item,'parameters') && item.parameters != "") {
          let jsonData = JSON.parse(item.parameters)
          if (Object.prototype.hasOwnProperty.call(jsonData, 'request_parameters'))
            url = this.constructURLWithRequestParams(item.url, jsonData.request_parameters)
          else if (Object.prototype.hasOwnProperty.call(jsonData, 'query_parameters'))
            url = this.constructURLWithQueryParams(item.url, jsonData.query_parameters)
        }
        router.push({
      name: 'Analytics',
      params: { provisionsName: item.name },
      query: { provisionsUrl: url }
    });
      }
  },
	props: {
     selectedInfrastructure: Object,
     handleClick: {
      type: Function
    },
    getLookupValue:{
      type:Function
    },
  },
  mounted() {
    this.activeTab = 'Meta';
    let provisions = this.selectedInfrastructure.provisions;
        for (var i = 0; i < provisions.length; i++) {
        this.provisionProperties[i] = provisions[i]
    }
  },
}

</script>

<style>
 .custom-tabs {
    font-size: 1rem; 
  }
  .custom-tabs .v-tab{

    padding: 0 1 0 0 !important;    
    margin: 0 !important;     
    font-size: 0.8rem !important;
    min-width: 30px !important;   
     border: 1px solid #ccc !important;
     border-radius: 5px !important;
  }
  
  .v-tabs--density-default {
    --v-tabs-height: 30px !important;
  }
 

.v-tabs__item:hover:not(.v-tabs__item--active) {
  border-color: #2196f3;
}
.v-card{
  padding: 5px !important;
}
  .v-container
  {
    /* padding: 10px !important; */
    /* height: 60px !important;
    overflow-y: auto !important; */
  }
  .v-window-item {
  transform: none !important;
  }
  
  .custom-active-tab {
    background-color: lightgray   !important;
    border: 1px solid gray !important;
    border-bottom: none !important;
  }
  .custom-card{
    padding: 0 !important;
    margin: 0 !important;
  }
  .custom-card .v-card-title{
    line-height: 1rem !important;    
    font-size: 1rem !important;
    padding: 1px;
  } 
  .custom-card .v-card-text{
    line-height: 1rem !important;    
    font-size: 1rem !important;
  
  } 
  .custom-card .v-label{
    font-size: 0.8rem;
  }
 .custom-card .v-col{
    font-size: 0.8rem;
    padding: 1px ;
  }
  
  .custom-card .v-row{
    font-size: 0.8rem;
    padding: 1px ;
 
  } 
  
  .max-height-container {
    height: 80px !important;
    max-height: 100px;
    overflow-y: auto;
  }
  .p{
    margin-bottom: 0.5rem !important;
  }

  .url-link {
  color: #007BFF;
  cursor: pointer;
}
</style>