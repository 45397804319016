<template>
  <v-container fluid class="change-password-container">
    <v-form @submit.prevent="onChangePassword" class="change-password-form" ref="form">
      <v-text-field v-model="currentPassword" :rules="currentPasswordRules" label="Current Password" :type="showCurrentPassword ? 'text' : 'password'" :append-inner-icon='showCurrentPassword ? "mdi-eye" : "mdi-eye-off"' @click:append-inner="showCurrentPassword = !showCurrentPassword"  ></v-text-field>
      <v-text-field v-model="newPassword" :rules="newPasswordRules" label="New Password" :type="showNewPassword ? 'text' : 'password'"  :append-inner-icon='showNewPassword ? "mdi-eye" : "mdi-eye-off"' @click:append-inner="showNewPassword = !showNewPassword"></v-text-field>
      <v-text-field v-model="confirmPassword" :rules="confirmPasswordRules" label="Confirm New Password" :type="showConfirmPassword ? 'text' : 'password'"  :append-inner-icon='showConfirmPassword ? "mdi-eye" : "mdi-eye-off"' @click:append-inner="showConfirmPassword = !showConfirmPassword"></v-text-field>
                <div   >
                <span :class=status.color>{{ status.message }}</span>
              </div>
      <v-btn  type="submit">Change Password</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useStore} from "@/store";

export default {
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      showCurrentPassword:false,
      showNewPassword: false,
      showConfirmPassword: false,
      status: {
        message: '',
        color: 'info',
      },
      rules: {
        required: value => !!value || 'Field is required',
      },
    };
  },
  methods: {
     ...mapActions(useStore, {changePassword: 'changePassword'}),

    async onChangePassword() {
            const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      this.status.message = ""
      if(this.newPassword == ''  || this.confirmPassword == '' || this.currentPassword == '' || this.newPassword != this.confirmPassword)
      {
        return
      }
      let status = await this.changePassword(this.currentPassword, this.newPassword);
      if (status == false)
      {
                this.status.message = "Invalid Credentials"
        this.status.color = "text-red"
      }
      else
      {
        this.status.message = "Password change successful"
        this.status.color = "text-green"
      }
    }
  },
  computed:{
    currentPasswordRules() {
      const rules = [];
      if (!this.currentPassword) {
        rules.push(value => !!value || 'Current Password is required');
      }
      return rules;
    },
    newPasswordRules() {
      const rules = [];
      if (!this.newPassword) {
        rules.push(value => !!value || 'New Password is required');
      }
      return rules;
    },
    confirmPasswordRules() {
      const rules = [];
      if (!this.confirmPassword) {
        rules.push(value => !!value || 'Confirm New Password is required');
      }
      if (this.newPassword !== this.confirmPassword) {
        rules.push(() => 'Passwords don\'t match');
      }
      return rules;
    },
  },
};
</script>

<style scoped>
.change-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 128px);
}

.change-password-form {
  width: 100%;
  max-width: 400px;
}

</style>
