import httpClient from '@/utils/request';
import {ORG_INFRA_URL} from "@/config/apiconfig";

async function post_saved_data_to_OrgInfra(parameters) {
    const response =
        await httpClient.post(ORG_INFRA_URL, parameters).then(function (response) {
            console.log("Post_saved resp" + response + "")
            return response;
        }).catch(function (error) {
            // handle error
            console.log(error);
        });
    return response;
}

async function get_saved_data_from_OrgInfra(parameters) {
    try {
        const response = await httpClient.get(ORG_INFRA_URL, {
            params: parameters,
        });

        if (response && response.data && response.data.results) {
            return response.data.results;
        } else {
            return 'Error occurred';
        }
    } catch (error) {
        console.log("Error at get_saved_Data function ", error);
        // Handle the error or return an error message
        throw new Error('Error occurred');
    }
}

async function delete_data_from_OrgInfra(parameters) {
  try {
    const response = await httpClient.delete(ORG_INFRA_URL, { data: parameters });
    console.log("Deleted resp", response);
    return response;
  } catch (error) {
    console.log("Error deleting data from OrgInfra", error);
    throw error; // Rethrow the error to handle it at the caller's end
  }
}


export {
    post_saved_data_to_OrgInfra,
    get_saved_data_from_OrgInfra,
    delete_data_from_OrgInfra
}