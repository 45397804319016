export const API_URL = process.env.VUE_APP_ADMIN_URL + "/api"
export const GET_INFRASTRUCTURES_URL = API_URL+"/infrastructures/"
export const GET_INFRASTRUCTURES_STRUCTURE_IDS_URL=API_URL+"/infrastructures/getStructureNumberInfo"
export const GET_ORG_URL = API_URL+"/organisation/"
export const GET_USER_URL = API_URL+"/users/"
export const GET_ADMIN_URL=API_URL+"/users/is_admin"
export const GET_BRIDGES_URL = API_URL+"/bridges/"
export const GET_INFRASTRUCTURE_DETAILS_URL = API_URL+"/infrastructures/"
export const GET_SURVEY_LATESTYEAR_URL = API_URL+"/surveys/latest_year/"
export const GET_AVAILABLE_SURVEY_YEARS_URL = API_URL+"/surveys/available_survey_years/"
export const GET_PROVISIONS_URL = API_URL+"/provisions/"
export const ORG_INFRA_URL = API_URL+"/org_infra/"
export const USER_INFRASTRUCTURE_URL = API_URL+'/userinfrastructures/'
// export const GET_ORG_INFRA_DATA_URL= API_URL+"/org_infra/get_saved_org_infra"
export const GET_STATISTICS_URL = API_URL+"/statistics/"
export const GET_APPLOOKUP_URL = API_URL+"/applookup/"
export const CHANGE_PASSWORD_URL=API_URL+"/users/change_password/"


//wagtail

export const LANDING_PAGE_URL =API_URL+'/v2/pages/nia-landing-page'
export const TERMS_PAGE_URL =API_URL+'/v2/pages/terms-and-conditions'


//LOGIN URL
export const GENERATE_TOKEN_URL =API_URL+"/token/"
export const REFRESH_TOKEN_URL= API_URL+"/token/refresh/"

//USER SETTINGS
export const  GET_USER_SETTINGS=API_URL+"/user-settings/"
export const GET_DEFAULT_SETTINGS=API_URL+"/default-settings/"
export const UPDATE_USER_IMAGE=API_URL+"/user-settings/update_image/"
export const UPDATE_USER_DETAILS=API_URL+"/user-settings/update_details/"