<template>
        <v-dialog
        v-model="dialog"
        activator="parent"
        class="login"
      >
        <v-card width="100%" class="mx-auto login-container">
          <v-card-title class="login-header">Login
          </v-card-title>
          <v-card-text>
            <v-form method="post" @submit.prevent="tnc">
              <v-text-field v-model="email" label="Email" prepend-icon="mdi-email" type="email" id="txtUserName" aria-label="Enter Email Address" data-test="userName"> </v-text-field>
              <v-text-field v-model="pwd" label="Password" prepend-icon="mdi-lock" :type="show1 ? 'text' : 'password'" id="txtPassword" :append-icon='show1 ? "mdi-eye" : "mdi-eye-off"' aria-label="Enter Password" data-test="password" @click:append="show1 = !show1"></v-text-field>
              <v-switch :color = applyColor label="Remember me" id="btnRememberMe" data-test="rememberMe"></v-switch>
              <v-switch :color = applyColor v-model="tnc" data-test="toggleTermsAndConditions">
                <template v-slot:label>
                  <span><a href="/terms-and-conditions" :class="{'tnc-light': currentTheme === 'light', 'tnc-dark': currentTheme === 'dark'}" target="_blank" id="btnTerms" data-test="termsAndConditions" aria-label="Go to Terms and Conditions Page">Accept Terms & Conditions</a></span>
                </template>
              </v-switch>
              <div v-if="auth_req === true" id="alert" style="color: red;" data-test="alert">
                {{ check_req }}
              </div>
              <v-btn id="btnLogin" :color = applyColor block class="mt-2" type="submit" @click="authUser" v-bind:disabled="!tnc" data-test="login">Login</v-btn>
              <a :class="{'tnc-light': currentTheme === 'light', 'tnc-dark': currentTheme === 'dark'}" href="#" id="btnForgotPwd" data-test="forgotPassword" aria-label="Retrieve Password">Forgot your Password?</a>
            </v-form>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-dialog>

</template>

<script>
import { useStore } from '@/store'
import { mapActions,mapState } from 'pinia'
import router from '@/router'


export default {
  name: 'Login',
	props: {
    currentTheme: String,
  },
  setup(){
  },
  computed: {
    ...mapState(useStore, ['getIsAuthenticated']),
    applyColor() {
      return this.currentTheme === 'dark' ? 'grey-lighten-1' : 'black';
    },
    },
  data() {

			return {
				email: '',
				pwd: '',
        auth_req: false,
        check_req: '',
        disableInput: true,
        dialog:false,
        tnc:false,
        show1: false,
        applookup:null
      }
		},

  methods: {
   
...mapActions(useStore, { login: 'obtainToken' , fetchApplookup:'fetchApplookup'}),
    // Toggle functionality
    toggleInput() {
  this.disableInput = !this.disableInput
},
    //Login User dispatch action
   async authUser() {
 let result = await this.login({email:this.email,password:this.pwd});

      if( result)
      {
       this.dialog = false;
       this.lstFilterLookup = this.applookup
    if (this.applookup == null){
      await this.fetchApplookup()
    }
         this.$router.push({ name: 'RefreshHome' });
      }
      else{
      this.auth_req=true;
      this.check_req="Invalid Credentials";
      }
      },
  }
}
</script>

<style lang="scss" scoped>
.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.2);

	display: flex;
	align-items: center;
	justify-content: center;
}

body {
  font-family: 'Open Sans', sans-serif;
}

.login {
  width: 400px;
  margin: 16px auto;
  font-size: 16px;
}


.login-header,
.login p {
  margin-top: 0;
  margin-bottom: 0;
}

.login-header {
  background: #111111;
  padding: 20px;
  font-size: 1.4em;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.login-container {
  padding: 25px;
}


.login p {
  padding: 12px;
}

.login input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  padding: 16px;
  outline: 0;
  font-family: inherit;
  font-size: 0.95em;
}

.login input[type="email"],
.login input[type="password"] {
  background: #fff;
  border-color: #bbb;
  color: #555;
}


.login input[type="email"]:focus,
.login input[type="password"]:focus {
  border-color: #888;
}

.login input[type="submit"] {
  background: #0c0c0c;
  border-color: transparent;
  color: #fff;
  cursor: pointer;
}

.login input[type="submit"]:hover {
  background: #313030;
}


.login input[type="submit"]:focus {
  border-color: #030303;
}

.flex-row
{
    display: flex;
    flex-direction: row;
}
.flex-justify
{
    display: flex;
    flex-direction: row;justify-content: space-between;
}
.icon
{
    height:8%;
    padding-top: 15px;
    padding-right: 10px;
}

.label
{
    margin-left: 10px;
}
label:after {
    content: "*";
    color: red;
}

.flex-wrap
{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 0px;
    padding-left: 0px;
    width: 100%;
}

.span
{
    margin-left:10px;
    color: black;
}

input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.toggle {
	cursor: pointer;
	text-indent: -9999px;
	width: 40px;
	height: 15px;
	background: lightgray;
	display: block;
	border-radius: 100px;
	position: relative;
}

.toggle:after {
	content: '';
	position: absolute;
	top: 0px;
	left: 1px;
	width: 15px;
	height: 15px;
	background: #fff;
	border-radius: 15px;
	transition: 0.3s;
}

input:checked + .toggle {
	background: #151515;
}

input:checked + .toggle:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.toggle:active:after {
	width: 20px;
}
.tnc-light
{
  text-decoration: revert;
  color: #0c0c0c;
  margin:0px;
}

.tnc-dark
{
  text-decoration: revert;
  color: #BDBDBD;
  margin:0px;
}
</style>