<template>
  <v-container fluid>
    <iframe
        :src="srcUrl"
        type="application/html"
        scrolling="yes"
        width="100%"
        height="800"
    ></iframe>
  </v-container>

</template>

<script>
import {ref, onMounted} from "vue";
import {mapState} from "pinia";
import {useStore} from "@/store";

export default {
  name: "Analytics",
  props:['name','url'],
    computed: {
    ...mapState(useStore, ['applookup']),
  },
  data(){
    return {
      srcUrl: this.url
    }
  },
  mounted(){
if (!this.srcUrl) {
  let sourceDetails = this.applookup.analytics_applications.filter(
    (item) => {
      return item.key === this.name;
    }
  );
  if (sourceDetails[0])
    this.srcUrl = sourceDetails[0].url;
}
  }
}
</script>

<style scoped>

</style>
