<template>
  <div>
    <div class="div-org">
      <v-autocomplete :items="lstOrg"
                      item-title="name"
                      item-value="code"
                      label="Organisation"
                      variant="solo"
                      v-model="selectedOrg"
                      @update:model-value="onChangeOrganisation"
      >
      </v-autocomplete>
    </div>
    <div class="div-row">
      <v-select :items="lstStates"
                item-title="name"
                item-value="code"
                label="State"
                variant="solo"
                style="width:10%"
                v-model="selectedState"
                @update:model-value="onChangeState"

      >

      </v-select>

      <div style="width:10%"></div>
      <v-select :items="lstStateCounty"
                item-title="name"
                item-value="code"
                label="County"
                variant="solo"
                style="width:10%"
                v-model="selectedCounty"
                @update:model-value="onChangeCounty"
      >
      </v-select>

      <div style="width:10%"></div>
      <v-select label="Rating"
                :items="lstRatings"
                item-title="value"
                item-value="key"
                v-model="selectedRating"
                variant="solo"
                style="width:10%"
      >

      </v-select>

      <div style="width: 10%"></div>
      <v-select label="Navigation control"
                :items="lstNavigationControl"
                item-title="value"
                item-value="key"
                v-model="selectedNavigationControl"
                variant="solo"
                style="width: 10%"
      ></v-select>
    </div>

    <v-container>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col :cols="4" :sm="6" :md="4" :lg="3">
          <v-card class="mx-auto" max-width="350">
            <v-toolbar-title style="color: gray; font: 16px">Unassigned</v-toolbar-title>
            <v-text-field
                clearable
                label="Search Unassigned Infrastructures"
                v-model="searchUnassigned"
                persistent-clear
                v-on:keyup.enter.prevent="searchUnassignedInfrastructures"
                append-inner-icon="mdi-magnify"
                @click:clear="clearSearchUnassigned"
                @click:append-inner="searchUnassignedInfrastructures"
            ></v-text-field>
            <div class="list-container">
              <v-list dense style="max-height: 350px; overflow-y: auto">
                <v-list-item v-for="item in lstUnAssigned" :key="item.id" max-width="auto">
                  <v-list-item-content>
                    {{ item.structure_number }}
                  </v-list-item-content>

                  <template v-slot:prepend>
                    <v-list-item-action start>
                      <v-checkbox-btn :model-value="item.selected"
                                      @change="toggleSelection(item)"></v-checkbox-btn>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list>
            </div>
          </v-card>

        </v-col>
        <v-col align="center">
          <div class="div-row">
            <v-btn
                @click="filterInfrastructures"
            >Filter
            </v-btn>
            <v-snackbar v-model="snackbar_filter_button.show" :color="snackbar_filter_button.color" location="top">
              {{ snackbar_filter_button.message }}
            </v-snackbar>
          </div>
          <br>
          <div class="divBtn">
            <v-btn @click="assignInfrastructures" style="width:10%"> &gt;&gt;</v-btn>
            <v-snackbar v-model="snackbar_assign_button.show" :color="snackbar_assign_button.color" location="top">
              {{ snackbar_assign_button.message }}
            </v-snackbar>
            <br>
            <v-btn @click="unassignInfrastructures" style="width:10%"> &lt;&lt;</v-btn>
            <v-snackbar v-model="snackbar_assign_button.show" :color="snackbar_assign_button.color" location="top">
              {{ snackbar_assign_button.message }}
            </v-snackbar>

          </div>
          <div class="divBtn">
            <br>
            <br>

            <v-btn @click="onSave">SAVE</v-btn>
            <v-snackbar
                v-model="snackbar.show"
                :color="snackbar.color"
                location="top">
              {{ snackbar.message }}
              <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                  Close
                </v-btn>
              </template>

            </v-snackbar>
          </div>
        </v-col>
        <v-col cols="4">
          <v-card class="mx-auto" max-width="350">
            <v-toolbar-title style="color: gray">Assigned</v-toolbar-title>
            <v-text-field
                clearable
                label="Search Assigned Infrastructures"
                v-model="searchAssigned"
                persistent-clear
                v-on:keyup.enter.prevent="searchAssignedInfrastructures"
                append-inner-icon="mdi-magnify"
                @click:clear="clearSearchAssigned"
                @click:append-inner="searchAssignedInfrastructures"
            ></v-text-field>
            <div class="list-container">
              <v-list dense style="max-height: 250px; overflow-y: auto">
                <v-list-item v-for="item in lstAssigned" :key="item.id" max-width="auto">
                  <v-list-item-content>
                    {{ item.structure_number }}
                  </v-list-item-content>

                  <template v-slot:prepend>
                    <v-list-item-action start>
                      <v-checkbox-btn :model-value="item.selected"
                                      @change="toggleSelection(item)"></v-checkbox-btn>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list>
            </div>
          </v-card>

        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <v-spacer></v-spacer>
        </v-col>

      </v-row>
    </v-container>
  </div>
  <v-spacer></v-spacer>
  <v-spacer></v-spacer>
  <v-spacer></v-spacer>

</template>
<script>
import States from '@/config/states'
import Counties from '@/config/county'
import {getAllOrg} from '@/api/assignbridge.api'
import {useStore} from '@/store'
import {mapActions, mapState} from 'pinia'
import {getInfrastructure_StructureNumberInfo} from "@/api/infrastructure.api";
import {post_saved_data_to_OrgInfra, get_saved_data_from_OrgInfra, delete_data_from_OrgInfra} from "@/api/org_infra.api"


export default {
  name: 'AssignOrgInfrastructure',
  data() {
    return {
      lstStates: States,
      lstCounty: Counties,
      lstRatings: [],
      lstStateCounty: [""],
      lstNavigationControl: [],
      lstOrg: [],
      lstAssigned: [],
      lstUnAssigned: [],
      lstInfrastructure: [],
      lstOrgAssigned: [],
      lstOrgUnAssigned: [],
      org_infra_params: {},
      query_params: {},
      delete_query_params: {},
      selectedInfraToAssign: [],
      selectedInfraToUnassign: [],
      selectedState: null,
      selectedCounty: null,
      selectedOrg: null,
      selectedRating: null,
      searchUnassigned: '',
      searchAssigned: '',
      selectedNavigationControl: null,
      showDropdownUnAssigned: false,
      showDropdownAssigned: false,
      snackbar: {
        show: false,
        message: '',
        timeout: 2000,
        top: true,
        color: ''
      },
      snackbar_assign_button: {
        show: false,
        message: '',
        top: true,
        color: 'info',
        timeout: 2000
      },
      snackbar_filter_button: {
        show: false,
        message: '',
        top: true,
        color: 'info',
        timeout: 2000
      }
    }
  },
  methods: {

    searchInfrastructures(searchIn, searchInfra) {
      let matchingInfrastructures = searchIn.filter((item) => {
        return (
            item.structure_number.toLowerCase().includes(searchInfra.toLowerCase())
        );
      });
      return matchingInfrastructures
    },
    searchUnassignedInfrastructures() {
      let matchingInfrastructures = this.searchInfrastructures(this.lstOrgUnAssigned, this.searchUnassigned)
      this.lstUnAssigned = matchingInfrastructures
    },
    clearSearchUnassigned() {
      this.lstUnAssigned = this.lstOrgUnAssigned
    },
    searchAssignedInfrastructures() {
      let matchingInfrastructures = this.searchInfrastructures(this.lstOrgAssigned, this.searchAssigned)
      this.lstAssigned = matchingInfrastructures
    },
    clearSearchAssigned() {
      this.lstAssigned = this.lstOrgAssigned
    },
    toggleSelection(item) {
      item.selected = !item.selected;
    },

    async populateData(assigned = false) {

      var infra_response, previous_saved_info;
      if (assigned) {
        const query_param = {'org_Id': this.selectedOrg};
        previous_saved_info = await get_saved_data_from_OrgInfra(query_param);
        infra_response = await getInfrastructure_StructureNumberInfo(query_param);
      }
      else {
          var inputParams = {
          "state_code": this.selectedState,
          "county_code": this.selectedCounty,
          "rating": this.selectedRating,
          "navigation_control": this.selectedNavigationControl
        }
        this.query_params["org_Id"] = this.selectedOrg
        previous_saved_info = await get_saved_data_from_OrgInfra(this.query_params);
        infra_response = await getInfrastructure_StructureNumberInfo(inputParams);
      }

      console.log("Prev and Infra", previous_saved_info, infra_response);

      if (previous_saved_info.length > 0) {
        const idsToRemove = new Set(previous_saved_info);
        const assignedData = infra_response.filter(obj => idsToRemove.has(obj.id));
        const unAssignedData = infra_response.filter(obj => !idsToRemove.has(obj.id));

        if (assigned) {
          this.lstAssigned = assignedData.map(({structure_number, id}) => ({structure_number, id}));
          console.log("Assigned", this.lstAssigned);
        } else {
          this.lstUnAssigned = unAssignedData;
          console.log("Unassigned", this.lstUnAssigned);
        }
      }
      else {
        console.log("Infra response", infra_response);
        this.lstUnAssigned = infra_response;
      }
    },
    async onSave() {
      var assigned_structure_numbers = []
      for (var i = 0; i < this.lstAssigned.length; i++) {
        assigned_structure_numbers.push(this.lstAssigned[i]['id'])
        console.log(this.lstAssigned[i]['id'])
      }
      console.log('this.lstAssigned' + JSON.stringify(this.lstAssigned))
      var params = {"orgId": this.selectedOrg, "structure_number": assigned_structure_numbers}
      console.log(params)
      var onSave_response = await post_saved_data_to_OrgInfra(params)
      this.delete_query_params['structure_number'] = []
      for (var j = 0; j < this.selectedInfraToUnassign.length; j++) {
        this.delete_query_params['org_id'] = this.selectedOrg
        this.delete_query_params['structure_number'].push(this.selectedInfraToUnassign[j]['id'])
      }
      console.log("query_orgs", JSON.stringify(this.delete_query_params))
      let delete_response = await delete_data_from_OrgInfra(this.delete_query_params)
      if (onSave_response.status == 201 || onSave_response.status == 200) {
        this.snackbar.color = 'success'
        this.snackbar.message = 'Saved successfully!'

        const infra = this.searchInfrastructures(this.lstOrgUnAssigned, this.searchUnassigned)
        const isSearchInfraSelected = this.selectedInfras.some((item) => item.id === infra.id && item.structure_number === infra.structure_number);
        if (isSearchInfraSelected) {
          console.log("isSearchInfraSelected = ", isSearchInfraSelected)
          this.lstOrgUnAssigned = this.lstOrgUnAssigned.filter(item => !infra.some(element => element.id === item.id));
        }
        this.lstOrgUnAssigned = this.lstUnAssigned
        this.lstOrgAssigned = this.lstAssigned
      } else {
        this.snackbar.color = 'error'
        this.snackbar.message = 'Error occured while saving'
      }
      this.snackbar.show = true
    },
    assignInfrastructures() {
      console.log("Values: \n Org : ", this.selectedOrg + "\n State: ", this.selectedState + "\n County: ", this.selectedCounty)
      this.selectedInfraToAssign = this.lstUnAssigned.filter(item => item.selected == true);
      console.log("SelectedInfraToAssign: ", JSON.stringify(this.selectedInfraToAssign))
      if (this.selectedInfraToAssign && this.selectedInfraToAssign.length == 0) {
        this.snackbar_assign_button.show = true
        this.snackbar_assign_button.message = 'Nothing selected to assign'
        console.log("Nothing to assign")
        return
      }
      for (var i = 0; i < this.selectedInfraToAssign.length; i++) {
        console.log("Inside loop ", this.selectedInfraToAssign[i])
        this.lstAssigned = this.lstAssigned.concat(this.selectedInfraToAssign[i])
        delete this.selectedInfraToAssign[i].selected;
      }
      const toRemove = this.selectedInfraToAssign
      console.log("ToRemve value from lstAssigned ", JSON.stringify(toRemove))
      this.lstUnAssigned = this.lstUnAssigned.filter(item => !toRemove.some(element => element.id === item.id));
      this.selectedInfraToAssign = []
    },

    async unassignInfrastructures() {
      this.selectedInfraToUnassign = this.lstAssigned.filter(item => item.selected == true);
      if (this.selectedInfraToUnassign && this.selectedInfraToUnassign.length == 0) {
        this.snackbar_assign_button.show = true
        this.snackbar_assign_button.message = 'Nothing selected to un-assign'
        console.log("Nothing to unassign")
        return
      }
      console.log("LstAssigned", JSON.stringify(this.lstAssigned))
      for (var i = 0; i < this.selectedInfraToUnassign.length; i++) {
        this.lstUnAssigned = this.lstUnAssigned.concat(this.selectedInfraToUnassign[i])
        delete this.selectedInfraToUnassign[i].selected;
      }
      const toRemove = this.selectedInfraToUnassign
      this.lstAssigned = this.lstAssigned.filter(item => !toRemove.some(element => element.id === item.id));
    },

    async onChangeOrganisation(selectObj) {
      console.log("In onChangeOrganisation : ", selectObj)
      this.selectedOrg = selectObj
      this.org_infra_params['org_Id'] = this.selectedOrg
      await this.populateData(true)
      this.showDropdownUnAssigned = false
      this.showDropdownAssigned = true
      this.lstOrgAssigned = this.lstAssigned

    },
    onChangeState(selectObj) {
      console.log("In onChangeState:", selectObj)
      console.log(this.selectedOrg)
      this.selectedState = selectObj;
      this.lstStateCounty = this.lstCounty.filter(function (item) {
        return item.state === selectObj;
      })[0].value.map(x => x);
      this.lstUnAssigned = []
      this.showDropdownUnAssigned = false
      this.showDropdownAssigned = true

    },
    onChangeCounty(selecteObj) {
      this.lstUnAssigned = []
      this.showDropdownUnAssigned = false
      this.showDropdownAssigned = true
    },
    async filterInfrastructures() {
      if (this.selectedOrg == null) {
        this.snackbar_filter_button.show = true
        this.snackbar_filter_button.message = 'No organisation selected'

        return
      }

      this.onChangeFilter()
      this.query_params["org_Id"] = this.selectedOrg
      await this.populateData()
      this.lstOrgUnAssigned = this.lstUnAssigned
    },

    onChangeFilter() {
      console.log(this.selectedOrg)
      this.query_params["state_code"] = this.selectedState
      this.query_params["county_code"] = this.selectedCounty
      this.query_params["rating"] = this.selectedRating
      this.query_params["navigation_control"] = this.selectedNavigationControl
    },

//Map actions in store
    ...mapActions(useStore, {fetchInfra: 'fetchBridges'}),


  },
  async mounted() {
    let org_response = await getAllOrg();
    this.lstOrg = org_response;
    this.lstRatings = this.applookup.rating;
    this.lstNavigationControl = this.applookup.navigation_control;
  },
  computed: {
    ...mapState(useStore, ['applookup']),
  },
}
</script>
<style scope>
.maincontent {
  height: 405px;
}


.div-org {
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.div-row {
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#content {
  width: 100%;
  padding: 0;
  min-height: calc(100% - 150px);
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

.content {

  padding-top: 100px;
}


.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
  height: 90%;
  color: black;
  border: 1px;
}

.divBtn {
  display: flex;
  flex-direction: column;
  width: 15%;
  align-items: center;
}

.divContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-height: 200px;
  overflow-y: auto;
}

.list-container {
  max-height: 300px;
  /* Set a maximum height for the container */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.list-title {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

@media (min-width: 600px) {
  .v-input__control .v-input__slot {
    width: 100%;
  }
}

@media (min-width: 960px) {
  .v-input__control .v-input__slot {
    width: 90%;
  }
}

@media (min-width: 1264px) {
  .v-input__control .v-input__slot {
    width: 80%;
  }
}

</style>
