import httpClient from '@/utils/request';
import {
    GET_BRIDGES_URL,
    GET_ORG_URL,
    GET_USER_URL, USER_INFRASTRUCTURE_URL,
} from '@/config/apiconfig';

 async function getAllOrg (){
  
    const response= 
    await  httpClient.get(GET_ORG_URL).
    then(function(response){
        return  response.data;
    }).catch(function (error) {
        // handle error
        console.log(error);
        });       
   return response;
}

 async function getOrgForUser (parameters){

    const response=
    await  httpClient.get(GET_ORG_URL,{
        params: parameters,
    }).
    then(function(response){
        return  response.data;
    }).catch(function (error) {
        // handle error
        console.log(error);
        });
   return response;
}

async function getAllUsers (){
  
    const response= 
    await  httpClient.get(GET_USER_URL).
    then(function(response){        
        return  response.data;
    }).catch(function (error) {
        // handle error
        console.log(error);
        });       
   return response;
}


async function getAllBridges (inputParams){
     const response=
    await  httpClient.get(GET_BRIDGES_URL,{
        params:inputParams
    }).
    then(function(response){        
        return  response.data;
    }).catch(function (error) {
        // handle error
        console.log(error);
        });       
   return response;
}

async function postUserInfrastructure (input){
     console.log(input)
     const response=
    await  httpClient.post(USER_INFRASTRUCTURE_URL, input).
    then(function(response){
        console.log(response)
        return response
    }).catch(function (error) {
        // handle error
        console.log(error);
        });
   return response;
}

async function getUserInfrastructure (inputParams){
     const response=
    await  httpClient.get(USER_INFRASTRUCTURE_URL,{
        params:inputParams
    }).
    then(function(response){
        return  response.data;
    }).catch(function (error) {
        // handle error
        console.log(error);
        });
   return response;
}

export{
getAllOrg,
getAllUsers,
getAllBridges,
    postUserInfrastructure,
    getUserInfrastructure
}