<template>
  <v-sheet>
    <v-navigation-drawer v-model="drawer">
      <Filter @filterChanged="plotMap" @setOverlay="overlay = !overlay"/>
    </v-navigation-drawer>
    <v-toolbar class="bg-black px-5">
      <v-app-bar-nav-icon class="text-grey" @click="toggleDrawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-autocomplete
          v-on:keyup.enter.prevent="searchStructures  "
          v-model:search-input="search"
          density="comfortable"
          label="Search in Displayed Structure Numbers "
          v-model="selectedStructure"
          v-virtual-scroll
          hide-details
          hide-no-data
          append-icon="mdi-magnify"
          @click:append.prevent="searchStructures"
          @input="updateSearchText"
          @click:clear="clearSearch"
          clearable
      ></v-autocomplete>
      <v-spacer></v-spacer>

      <label><b>Bridges # </b><b
          id="totalnumber">{{ this.infrastructures && this.infrastructures.length }} /
        {{ this.totalAvailableInfrastructures }}</b>
      </label>
    </v-toolbar>
    <v-alert
        type="info"
        style="padding:2px"
        v-model="showWarning"
        variant="outlined"
        closable
    >
      Search By Structure Number : Missing Latitude and Longitude information for {{ countMissingGeoInfo }} structures.
      Structure Numbers are {{ textMissingGeoInfo }}
    </v-alert>
    <v-overlay
        :model-value="overlay"
        class="align-center justify-center"
        persistent="true">
      <v-progress-circular
          color="primary"
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>

    <!--      the map -->
    <div class="col-xl-12 col-md-12 col-sm-12">
      <div class="card">
        <div id="mapDiv" class="map">
        </div>
      </div>
    </div>

    <div class="px-5">
      <v-slider
          :min="minYear"
          :max="maxYear"
          :step="1"
          thumb-label="always"
          show-ticks="always"
          tick-size="4"
          color="green"
          v-model="selectedYear"
          @update:modelValue="onChangeYear"
      ></v-slider>
    </div>
  </v-sheet>
</template>

<script>
import {Loader, OverlappingMarkerSpiderfier} from '@googlemaps/js-api-loader'
import {MarkerClusterer, SuperClusterAlgorithm} from "@googlemaps/markerclusterer";
import {mapActions, mapState, mapWritableState} from "pinia";
import {useStore} from "@/store";
import Filter from './Filter.vue';
import InfrastructureDetails from './InfrastructureDetails.vue';
import {createApp} from 'vue';
import {vuetify} from '../main'
import {getLookupValue} from "../utils/lookup"
import {loginTheme} from '../store/theme'

const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY

export default {
  name: 'MapDashboard',
  components: {
    Filter,
    InfrastructureDetails
  },
  data() {
    return {
      drawer: false,
      markers: [],
      lstFilterLookup: [],
      overlay: true,
      selectedStructure: null,
      search: '',
      showWarning: false,
      maxYear: 0,
      minYear: 0,
    }
  },
  computed: {
    ...mapState(
        useStore,
        {
          infrastructures: "lstInfrastructure",
          structureDetails: "structureDetails",
          latestSurveyYear: "latestSurveyYear",
          getInfrastructureNext: "getInfrastructureNext",
          getStructureNumbes: "getStructureNumbes",
          totalAvailableInfrastructures: "totalAvailableInfrastructures",
          availableSurveyYears: "availableSurveyYears",
          availableSurveyYearMin: "availableSurveyYearMin",
          availableSurveyYearMax: "availableSurveyYearMax",
          applookup: "applookup"
        }),
    ...mapWritableState(
        useStore, {
          selectedState: "selectedState",
          selectedCounty: "selectedCounty",
          selectedRating: "selectedRating",
          selectedNavigationControl: "selectedNavigationControl",
          selectedBridgeMedian: "selectedBridgeMedian",
          selectedStructureFlared: "selectedStructureFlared",
          selectedTypeOfServiceOnBridge: "selectedTypeOfServiceOnBridge",
          selectedTypeOfServiceUnderBridge: "selectedTypeOfServiceUnderBridge",
          selectedProvision: "selectedProvision",
          selectedYear: "selectedYear",
        }
    ),
    getStructureLookup() {
      return this.getStructureNumbes;
    },
    textMissingGeoInfo() {
      var filteredInfrastructures = this.infrastructures.filter(item => {
        if (item.latitude === '0.0' || item.longitude === '0.0')
          return item.structure_number
      });
      const missingData = filteredInfrastructures.map(item => item.structure_number);

      let missingStructures = missingData.slice(0, 2).join(',');
      if (missingData.length > 2) {
        missingStructures += '...';
      }
      return missingStructures;
    },
    countMissingGeoInfo() {
      var filteredInfrastructures = this.infrastructures.filter(item => {
        if (item.latitude === '0.0' || item.longitude === '0.0')
          return item.structure_number
      });
      return filteredInfrastructures.length;
    },
  },

  methods: {
    ...mapActions(
        useStore,
        {
          setInfra: 'setLstInfrastructures',
          getLatestSurveyYear: 'getLatestSurveyYear',
          getStructureInfo: 'getStructureInfo',
          getProvisions: 'getProvisions',
          filterStructures: 'filterStructures',
          getAvailableSurveyYears: 'getAvailableSurveyYears',
          fetchApplookup:'fetchApplookup',
        },
    ),

    getMarkerDetails(marker, infoWindow, map, item) {

      marker.addListener("click", async (mapsMouseEvent) => {
        //GET STRUCTURE DETAILS of bridge selected
        await this.getStructureInfo(item.id, this.latestSurveyYear);
        const childComponent = document.createElement("div");
        infoWindow.setContent(childComponent);
        createApp(InfrastructureDetails, {
          selectedInfrastructure: this.structureDetails,
          handleClick: this.handleClick,
          getLookupValue: getLookupValue,
        }).use(vuetify).mount(childComponent);
        infoWindow.open(map, marker);
        infoWindow.setPosition(mapsMouseEvent.latLng.toJSON());

      });

    },
    toggleDrawer() {
      this.drawer = !this.drawer
    },
    assignMarkerColor(structureRating) {
      if (structureRating == '') structureRating = 'default';
      return this.lstFilterLookup.rating.find(item => item.key === structureRating).color;
    },
    plotMap() {
      var map = new google.maps.Map(mapDiv, {
        center: {lng: -98.5556199, lat: 39.8097343},
        // initial zoom
        zoom: 4.2,
        minZoom: 3,
        // maxZoom: 13,
        streetViewControl: true,
        fullscreenControl: true,
        mapTypeControl: false,
        styles: [
          {
            "featureType": "landscape",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi.business",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          }
        ],
      })

      var markerCluster = null
      this.markers.length = 0
      // Define Circle Styles
      var circle = {
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 0.6,
        strokeOpacity: 1,
        scale: 8,
        strokeColor: 'white',
        strokeWeight: 1.5,
        fillColor: "#C3B1E1"
      }

      // Create the initial InfoWindow.
      let infoWindow = new google.maps.InfoWindow({
        content: "<div id='info-window-content'></div>",
      });

      //Plot the markers on the map    
      for (var i = 0; i < this.infrastructures.length; i++) {
        var item = this.infrastructures[i];
        circle.fillColor = this.assignMarkerColor(item.survey[0].deck_rating);
        var markerPosition = new google.maps.LatLng(item.latitude, item.longitude);
        var marker = new google.maps.Marker({
          position: markerPosition,
          map: map,
          icon: circle,
          optimized: true
        });
        //call to add event handler to each marker
        this.getMarkerDetails(marker, infoWindow, map, item);
        this.markers.push(marker);
      }
      //Set Cluster config
      markerCluster = new MarkerClusterer({
        map: map,
        markers: this.markers,
        algorithm: new SuperClusterAlgorithm({minPoints: 7})
      });
      this.overlay = false;
    },
    handleClick() {
      //TO DO ANY API CALLS OR FUNCTIONALITY OT BE HANDLED WHEN TAB IS CLICKED IN INFO WINDOW
    },
    updateSearchText(event) {
      this.search = event.target.value;
    },
    searchStructures() {
      this.selectedStructure = this.search;
      this.filterStructures(this.search);
      this.plotMap();
      this.showWarning = this.countMissingGeoInfo > 0;
    },
    clearSearch() {
      this.selectedStructure = null;
      this.search = '';
      this.searchStructures();
    },
    async onChangeYear() {
      this.overlay = true
      let query_params = this.constructQueryParameters();

      await this.setInfra(query_params);
      this.showWarning = this.countMissingGeoInfo > 0;
      this.plotMap()
    },
    constructQueryParameters(isInitialLoad = false) {
      let query_params = {
        state_code: this.selectedState == null ? '' : this.selectedState.code,
        county_code: this.selectedCounty == null ? '' : this.selectedCounty,
        rating: this.selectedRating == null ? '' : this.selectedRating,
        navigation_control: this.selectedNavigationControl == null ? '' : this.selectedNavigationControl,
        bridge_median: this.selectedBridgeMedian == null ? '' : this.selectedBridgeMedian,
        structure_flared: this.selectedStructureFlared == null ? '' : this.selectedStructureFlared,
        type_of_service_on_bridge: this.selectedTypeOfServiceOnBridge == null ? '' : this.selectedTypeOfServiceOnBridge,
        type_of_service_under_bridge: this.selectedTypeOfServiceUnderBridge == null ? '' : this.selectedTypeOfServiceUnderBridge,
        year: isInitialLoad ? this.latestSurveyYear : this.selectedYear == null ? this.latestSurveyYear : this.selectedYear,
        provision: this.selectedProvision == null ? '' : this.selectedProvision
      };
      return query_params;
    }
  },
  setup() {
    // loginTheme()
  },
  async created(){
          this.lstFilterLookup = this.applookup
    if (this.applookup == null){
      await this.fetchApplookup()
                this.lstFilterLookup = this.applookup
    }
  },
  async mounted() {

    const loader = new Loader({apiKey: GOOGLE_MAPS_API_KEY})
    // Load Google Maps
    const mapDiv = document.getElementById("mapDiv");
    await loader.load()

    await this.getLatestSurveyYear();
    await this.getProvisions();

    let query_params = this.constructQueryParameters(true);
    await this.setInfra(query_params);
    this.showWarning = this.countMissingGeoInfo > 0;
    this.plotMap()

    await this.getAvailableSurveyYears();
    this.maxYear = this.availableSurveyYearMax;
    this.minYear = this.availableSurveyYearMin;
    this.selectedYear = this.latestSurveyYear;
  },

}


</script>

<style scope>

#mapDiv {
  width: 100%;
  height: 60vh;
  margin: 0px 0 0 0;
}

</style>