import httpClient from '@/utils/request';
import {LANDING_PAGE_URL} from '@/config/apiconfig';

async function loadLandingPage (){
     const response=
    await  httpClient.get(LANDING_PAGE_URL,{
    }).
    then(function(response){
        return  response.data;
    }).catch(function (error) {
        // handle error
        console.log(error);
        });
   return response;
}
export{
 loadLandingPage
}