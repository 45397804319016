@@ -0,0 +1,425 @@
<template>
    <div class="maincontent">
    
            <div id="content" >
              <div class="content">
               
                <div class="card" style="margin-top: -30px; padding: 5px; background-color: rgb(51, 51, 51); color: white; font-size: 20px; font-family: &quot;Arial Narrow&quot;, sans-serif; text-align: center;">
                  <div class="row"><div class="col-lg-1 col-md-1"></div>
                  <div class="col-lg-4 col-md-5" style="text-align: left;">
                    <label><b>Total Bridges : </b><b id="totalnumber"></b></label>
                  </div>
                  <div class="col-lg-7 col-md-6">
                    <div style="box-sizing: border-box; padding: 5px;">
                   <label><b> UnAssigned : </b>
                      <b id="totalnumber"></b></label></div></div></div><div class="row">
                        <div class="col-sm-1"></div><div class="col" style="text-align: left; font-size: 18px; font-family: &quot;Arial Narrow&quot;, sans-serif;">
                          <span style="text-align: left;"></span></div></div></div>

      <div class="div-row">
          <v-select :items="lstOrg"
           item-title= "name"
          item-value="code" 
          label = "Organisation"                    
          variant="solo"  
          style="width:30%"
          >            
          </v-select>
          <div style="width:10%"></div>
          <v-autocomplete 
              label="User"
              :items="lstUser"
              
              clearable    
              item-title="name"              
              item-value="id"      
              variant="solo"  
              
          style="width:30%"
              ></v-autocomplete>  
         

             </div>
             <div class="div-row">
            
              <v-select :items="lstStates"
           item-title= "name"
          item-value="code" 
          label = "State"          
          variant="solo" 
          style="width:30%"
          v-model="selectedState"
          @update:model-value="onChangeState"
          >
            </v-select>
            
          <div style="width:10%"></div>
          <v-select :items="lstStateCounty"
           item-title= "name"
          item-value="code" 
          label = "County"          
          variant="solo"   
          style="width:30%"
          v-model="selectedCounty"
          >
          </v-select>

          <div style="width:10%"></div>
          <v-select label="Rating"
          :items="lstRatings"
          item-title= "value"
          item-value="key" 
          v-model="selectedRating"
          variant="solo"   
          style="width:20%"
          >

          </v-select>
             </div>
                <div class="div-row">
             
                <v-btn
                @click="filterBridges"
                > Filter </v-btn>
              </div>  
              <br>
              
             <div class="divContent">

              <v-autocomplete 
              label="Un Assigned"
              :items="lstUnAssigned"
              multiple
              clearable
              variant="solo"              
              item-title="structure_number"
              item-value="structure_number"
              ></v-autocomplete>
              <div class="divBtn">
              <v-btn style="width:10%">>></v-btn>
              <br>
              <v-btn  style="width:10%"> </v-btn>
            </div>
              <v-autocomplete
              :items="lstAssigned"
              label="Assigned"
              multiple
              clearable
              item-title="structure_number"
              item-value="structure_number"              
              variant="solo"
              ></v-autocomplete>
             </div>
             <br>
             <br>
                          <div>
              <v-btn>SAVE</v-btn>
             </div>
             </div>
          </div>
          </div>
        
    </template>
    <script>
    //Load static JSON data for the filters
    import States from '@/config/states'
    import Counties from '@/config/county'
    import { getAllOrg} from '@/api/assignbridge.api'
    import { useStore } from '@/store'
    import { mapActions,mapState } from 'pinia'

    export default {
      name: 'AssignBridge',
      data(){
    return{
    
          lstStates: States,
          lstCounty: Counties,          
          lstRatings:  [],
          lstStateCounty: [],
          lstOrg:[],
          lstAssigned:[],
          lstUnAssigned:[],  
          lstUser:[],    
          selectedState:'All',
          selectedCounty:'All',
          selectedOrg:'All',
          selectedRating: 'All',       
    }
      },
      methods:{
        //Event on change of state dropdown
        onChangeState(selectObj) {
        //load counties of the state
        
        this.selectedState=selectObj;
        this.lstStateCounty = this.lstCounty.filter(function (item) {
          return item.state === selectObj;
        })[0].value.map(x => x);

      }, 
      //filter bridges based on filters
     async filterBridges()
      {
        var inputParams={"state_code": this.selectedState,"county_code":this.selectedCounty,"rating":this.selectedRating}
        this.lstAssigned= await this.fetchBridges(inputParams);
        this.lstUnAssigned= await this.fetchBridges(inputParams);
      },

      //Map actions in store  
      ...mapActions(useStore, { fetchUsers: 'fetchUsers' ,fetchBridges: 'fetchBridges' }), 
 

      },
      setup()
      {      
      },
    async   mounted() {
      this.lstOrg= await getAllOrg();
     // this.lstUser = await this.fetchUsers();
      this.lstRatings = this.applookup.rating
      
    },
      computed: {
      ...mapState(useStore, ['applookup']),
      },

    }
    </script>
    <style scope>
    .maincontent{
        height: 504px;
    }
    .container {
        width: 100%;

        padding-right: 15px;
        /* padding-left: 15px; */
        margin-right: auto;
        margin-left: auto;
        /* max-width: 500px; */
    }
    
    .div-row{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    #content {
    width: 100%;
    padding: 0;
    min-height: calc(100% - 150px);
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

.content{

  padding-top:100px;
}


        .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.header
{
  display: flex;
flex-direction: row;
justify-content: space-evenly;
width: 90%;
height: 90%;
color: black;
border: 1px;
}
.divBtn{
display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center;
}
.divContent{
display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

    </style>