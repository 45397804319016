import { useTheme } from 'vuetify';
import { ref } from 'vue';
import {useStore} from '../store/index';

function getThemeSettingValue(settings) {
  if (settings) {
    const themeSetting = settings.find(s => s.setting.name === "Theme");
    return themeSetting ? themeSetting.value : null;
  }
  else
    return null
}

export function loginTheme(){
  const storeVar = useStore();
  const userSettings = storeVar.userSettings;
  let themetoSet = getThemeSettingValue(userSettings);
      if (themetoSet == null) {
           themetoSet = sessionStorage.getItem('theme');
        }
  const theme = useTheme();
  const currentTheme = ref(themetoSet == null ? 'light' : themetoSet);
  const newTheme = themetoSet=='dark' ? 'dark' : 'light';
  if (newTheme=='dark') {
     theme.global.current.value.dark=true
  }
  else{
      theme.global.current.value.dark=false
  }
  theme.global.name.value = newTheme;
  sessionStorage.setItem('theme', newTheme);
  currentTheme.value = newTheme;
}

export function useThemeSwitcher() {
  const theme = useTheme();
  const themeSet = sessionStorage.getItem('theme');
  const currentTheme = ref(themeSet == null ? 'light' : themeSet);
  const toggleTheme = () => {
    const newTheme = theme.global.current.value.dark ? 'light' : 'dark';
    theme.global.name.value = newTheme;
if (newTheme=='dark') {
     theme.global.current.value.dark=true
  }
      else{
      theme.global.current.value.dark=false
  }
      sessionStorage.setItem('theme', newTheme);
    currentTheme.value = newTheme;
  };
  theme.global.name.value = themeSet == null ? 'light' : themeSet;
  if (themeSet=='dark') {
     theme.global.current.value.dark=true
  }
  else{
      theme.global.current.value.dark=false
  }
  return { theme, toggleTheme, currentTheme };
}
