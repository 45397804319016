<template>
  <v-container fluid>
        <v-overlay
        :model-value="overlay"
        class="align-center justify-center"
        persistent="true">
      <v-progress-circular
          color="primary"
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="2">
        <v-autocomplete :items="metrics"
                        item-title="value"
                        item-value="key"
                        label="Select Option"
                        variant="solo"
                        v-model="selectedOption"
                        @update:modelValue="onChange"
                        clearable
                        single-line></v-autocomplete>
      </v-col>
      <v-col>
        <v-slider
            :min="minYear"
            :max="maxYear"
            :step="1"
            thumb-label="always"
            show-ticks="always"
            tick-size="4"
            color="#C8E6C9"
            track-color="#4CAF50"
            thumb-color="green"
            v-model="selectedYear"
            :ticks="years"
            @update:modelValue="onChange"
        ></v-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" id="myContainer">
        <div id="legend" class="legend-container"></div>
      </v-col>
      <v-col>
        <NIAAnalyticsDetails ref="analyticsDetailsComponent" :selectedOptionValue="selectedOptionValue" :stateName="selectedStateName" :stateMetrics="selectedStateMetrics" :tabs="categories" :year="selectedYear"/>
        <div id="mapContainer" class="d3-map-container"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as d3 from 'd3';
import {mapActions, mapState} from "pinia";
import {useStore} from "@/store";
import * as topojson from 'topojson-client';
import States from '@/config/states';
import NIAAnalyticsDetails from "@/components/NIAAnalyticsDetails.vue"

export default {
  name: "NIAAnalytics",
  data() {
    return {
      years: [],
      selectedOption: null,
      selectedYear: null,
      maxYear: null,
      minYear: null,
      width: 975,
      height: 610,
      usJsonFile: '/stateVisualization/us.json',
      lstStates: States,
      metrics: [],
      tickLabels: [],
      selectedOptionValue: null,
      selectedStateName:"",
      selectedStateMetrics:null,
      categories: ["Standard Statistics"],
      sortedMetrics: [],
      overlay: false
    }
  },
  computed: {
    ...mapState(
        useStore,
        {
          statisticsData: "statisticsData",
          availableSurveyYearMin: "availableSurveyYearMin",
          availableSurveyYearMax: "availableSurveyYearMax",
          applookup:"applookup"
        }),
  },
  methods: {
    ...mapActions(
        useStore,
        {
          getStatisticalData: 'getStatisticalData',
          getAvailableSurveyYears: 'getAvailableSurveyYears'
        },
    ),
    async onChange() {
      if (!this.selectedYear) {
        alert("Select a Year")
        return
      }
      if (!this.selectedOption) {
        alert("Select an option")
        return
      }
      this.createMap(this.selectedYear, this.selectedOption)
    },
    calculateDomain(option, data) {
      const optionValues = Object.values(data);
      const maxOptionValue = Math.max(...optionValues);
      const minOptionValue = Math.min(...optionValues);
      return [minOptionValue, maxOptionValue];
    },
    createMap(selectedYear, selectedOption) {
      const selectedOptionValue = this.getValueByKey(selectedOption)
      d3.select('#mapContainer').selectAll('*').remove();

      let temp = this.statisticsData["years"][selectedYear]["states"]
      let dataMapping = [];
      for (const stateCode in temp) {
        const stateObject = States.find(state => state.abbreviation === stateCode);
        const data = temp[stateCode][selectedOption];

        dataMapping[stateObject.code] = data;
      }
      this.sortedMetrics = Object.entries(dataMapping).map(([code, value]) => ({ code, value }));

      this.sortedMetrics.sort((a, b) => b.value - a.value);

      const deckRatingData = dataMapping
      const svg = d3.select('#mapContainer')
          .append('svg')
          .attr('width', this.width)
          .attr('height', this.height);

      let projection = d3.geoAlbersUsa().translate([this.width / 2, this.height / 2]);

      const path = d3.geoPath();

      const colorDomain = this.calculateDomain(selectedOption, deckRatingData);

      const minValue = colorDomain[0];
      const maxValue = colorDomain[1];

      const darkerColorMin = d3.interpolateBlues(0.3);
      const darkerColorMax = d3.interpolateBlues(1);

      // Create a color scale that interpolates between the darker color and the normal color
      const colorScale = d3.scaleSequential(d3.interpolateRgb(darkerColorMin, darkerColorMax))
          .domain([minValue, maxValue]);

      // Load the TopoJSON file
      d3.json(this.usJsonFile).then(us => {

        const states = topojson.feature(us, us.objects.states);

        // Append state paths for choropleth
        svg.selectAll('path')
            .data(states.features)
            .enter()
            .append('path')
            .attr('fill', d => {
              const stateCode = d.id
              let deckRating = deckRatingData[stateCode];
              // console.log(`State: ${stateCode}, Deck Rating: ${deckRating}`);
              return colorScale(deckRating);
            })
            .attr('d', path)
            .on('click', (event, d) => {
              const stateCode = d.id;
              this.selectedOptionValue = selectedOptionValue
              this.selectedStateName = this.getStateNameByCode(stateCode);
              this.selectedStateMetrics = {};
              const abbr = this.getStateAbbreviationByCode(stateCode);
              const currentStateMetrics = temp[abbr];
              for (let val in currentStateMetrics) {
                const key = this.getValueByKey(val);
                this.selectedStateMetrics[key] = currentStateMetrics[val];
              }
              let ranking = this.getStateRankingByCode(stateCode)
              this.$refs.analyticsDetailsComponent.showDetailsForState(event, ranking);
            });
      })
      this.createLegend(selectedOptionValue, colorDomain)
    },
    createLegend(legendTitle, legendRange) {
      d3.select('#legend').selectAll('*').remove();
      const legendContainer = d3.select('#legend');
      const legendContainerDim = d3.select('#myContainer').node();
      let legendWidth = legendContainerDim.clientWidth;
      let legendHeight = legendContainerDim.clientHeight * 0.8; // Adjust the height as needed
      const legend = legendContainer
          .append('svg')
          .attr('class', 'legend')
          .attr('width', legendWidth)
          .attr('height', legendHeight);

      const min = legendRange[0];
      const max = legendRange[1];

      const darkerColorMin = d3.interpolateBlues(0.3);
      const darkerColorMax = d3.interpolateBlues(1);

      // Create a color scale that interpolates between the darker color and the normal color
      const legendColorScale = d3.scaleSequential(d3.interpolateRgb(darkerColorMin, darkerColorMax))
          .domain([min, max]);

      const legendValues = d3.range(min, max, (max - min) / 10); // Adjust number of steps as needed

      const legendItemHeight = legendHeight / 10;
      const legendSpacing = legendItemHeight;

      legend
          .append('text')
          .attr('x', legendWidth / 2) // Center the title
          .attr('y', 20) // Vertical positioning
          .attr('dy', '0.35em')
          .attr('text-anchor', 'middle') // Center align text
          .text(legendTitle);

      legend
          .selectAll('rect')
          .data(legendValues)
          .enter()
          .append('rect')
          .attr('x', 10) // Horizontal positioning
          .attr('y', (d, i) => i * legendSpacing + 40)
          .attr('width', 20) // Width of each legend item
          .attr('height', legendItemHeight)
          .attr('fill', d => legendColorScale(d)); // Scale the value based on the range

      // Create legend labels
      legend
          .selectAll('text.legend-label')
          .data(legendValues)
          .enter()
          .append('text')
          .attr('class', 'legend-label')
          .attr('x', 40) // Horizontal positioning
          .attr('y', (d, i) => i * legendSpacing + 40 + legendItemHeight / 2)
          .attr('dy', '0.35em')
          .text(d => d.toFixed(2)); // Format values based on the range
    },
    getValueByKey(key) {
      const item = this.metrics.find(item => item.key === key);
      return item.value;
    },
    getStateNameByCode(code) {
      const state = States.find(state => state.code === code);
      return state ? state.name : null;
    },
    getStateAbbreviationByCode(code) {
      const state = States.find(state => state.code === code);
      return state ? state.abbreviation : null;
    },
    getStateRankingByCode(code) {
      const ranking = this.sortedMetrics.findIndex(item => item.code === code);
      return ranking + 1
    }
  },
  components: {
    NIAAnalyticsDetails
  },
  created() {
    this.metrics = this.applookup.metrics
  },
  async mounted() {
    this.overlay = true
    try {
      await this.getStatisticalData()
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    await this.getAvailableSurveyYears();
    this.maxYear = this.availableSurveyYearMax;
    this.minYear = this.availableSurveyYearMin;
    for (let year = this.minYear; year <= this.maxYear; year++) {
      this.years.push(year.toString());
    }
    this.selectedYear = this.maxYear
    this.selectedOption = 'avg_deck_rating'
    if (process.env.NODE_ENV === 'production')
      this.usJsonFile = '/static/stateVisualization/us.json'

    this.metrics.sort((a, b) => a.value.localeCompare(b.value));

    this.createMap(this.selectedYear, this.selectedOption);
    this.overlay = false
  },
};
</script>

<style>

.legend-container {
  width: 100%;
}

.d3-map-container {
  width: 100%;
}

</style>
