import httpClient from '@/utils/request';
import {
    USER_INFRASTRUCTURE_URL,
}
    from '@/config/apiconfig';

 async function getInfrastructuresForUser (parameters){
     const response =
         await httpClient.get(USER_INFRASTRUCTURE_URL, {
             params: parameters,
         }).
    then(function(response){
        return  response.data.results;
    }).catch(function (error) {
        console.log(error);
        });
   return response;
}

 async function deleteUserInfrastructure (parameters){
     const response =
         await httpClient.delete(USER_INFRASTRUCTURE_URL, {
             params: parameters,
         }).
    then(function(response){
        return  response;
    }).catch(function (error) {
        console.log(error);
        });
   return response;
}


export{
 getInfrastructuresForUser,
    deleteUserInfrastructure
}