<template>
  <footer id="footer">
    <v-footer class="bg-black pa-0" >
      <v-container fluid>
        <v-row class="d-flex align-center">
          <v-col class="pa-0 ma-0">
            <a href="#">
          <img id="nsfLogo" alt="NIA Logo" aria-label="Go to NSF website" class="fimg float-left px-4"
           data-test="nsfLogo" src="../assets/nsf_logo.png" ></a>
          </v-col>
          <v-spacer class="d-none d-sm-flex"></v-spacer>
          <v-col class="d-none d-sm-flex" cols="6">
            <div>
              <p id="footerDisclaimer" data-test="footerDisclaimer" class="footer-disclaimer">
                National Infrastructure Survey is a product of collaboration with Nebraska DOT - Bridge vision and the
                University of Nebraska at Omaha. This work is funded by National Science Foundation (NSF) and FHWA and
                LTBP
                Infobridge provided valuable assistance and access to data needed for support the development of tools
                for
                decision support system for bridge engineers.</p>
            </div>
          </v-col>
          <v-spacer class="d-none d-sm-flex"></v-spacer>
          <v-col class="pa-0 ma-0">
            <div >
              <span><a href="#"><img id="unoLogo" aria-label="Go to UNO website" data-test="unoLogo" src="../assets/uno_logo_white.png" alt="UNO Logo" class="fimg"></a></span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </footer>

</template>
<script>

export default {
  name: 'Footer'
}
</script>
<style>
#footer {
  position: relative;
  bottom: 0;
  text-align: center;
  background-color: #000000;
  width: 100%;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px;
}

.footer-disclaimer {
  color: #BBBBBB;
  font-size: 14px;
  line-height: 1;
  padding-top: 10px;
  font-family: "Arial Narrow", serif;
  text-align: justify;
  /*padding-left: 100px;*/
  /*max-height: 100px;*/
}

.row {
  display: flex;
  flex-direction: row;
}

.fimg {
  /*justify-self: right;*/
  max-width: 160px;
  max-height: 100px;

}
</style>
