import {
    REFRESH_TOKEN_URL,
} from '@/config/apiconfig';
import {defineStore} from 'pinia'
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {getToken,is_admin} from '@/api/login.api';
import {
    getAllBridges,
    getAllUsers
}
    from '@/api/assignbridge.api';
import {
    getInfrastructure,
    getInfrastructureDetails,
    getSurveyLatestYear,
    getInfrastructureNext,
    getProvisions,
    getAvailableSurveyYears, getStatistics,
    postChangePassword,
    getUserSettings,
    getDefaultSettings,
    updateUserImageToS3,
    updateUserDetails
}
    from "@/api/infrastructure.api";
import {loadLandingPage} from "@/api/home.api";
import {getApplookup} from "@/api/applookup.api"
const defaultStateCode = "31"
export const useStore = defineStore('main', {

    state: () => ({
            lstInfrastructure: [],
            initialStructures:[],
            isAuthenticated: sessionStorage.getItem('token') != null ? true : false,
            endpoints: {
                refreshJWT: REFRESH_TOKEN_URL
            },
            username: "",
            userAvatarPath:sessionStorage.getItem('userAvatarPath') == null ? '' : sessionStorage.getItem('userAvatarPath'),
            isUserAdmin:sessionStorage.getItem('isUserAdmin') == null ? false : sessionStorage.getItem('isUserAdmin'),
            accessToken: null,
            refreshToken: null,
            lstUsers: [],
            lstUnAssignedBridges: [],
            lstAssignedBridges: [],
            infrastructureNext: null,
            totalAvailableInfrastructures: 0,
            homePage: "",
            email: sessionStorage.getItem('email') != null ? JSON.parse(sessionStorage.getItem('email')) : "",
            structureDetails: {},
            latestSurveyYear: sessionStorage.getItem('defaultYear') || null,
            provisions: [],
            availableSurveyYearMax:0,
            availableSurveyYearMin:0,
            defaultStateCode: defaultStateCode,
            selectedState: {"name":"", "code":defaultStateCode},
            selectedCounty: null,
            selectedRating: null,
            selectedNavigationControl: null,
            selectedBridgeMedian: null,
            selectedStructureFlared: null,
            selectedTypeOfServiceOnBridge: null,
            selectedTypeOfServiceUnderBridge: null,
            selectedProvision:null,
            selectedYear:null,
            statisticsData: null,
            defaultSettings: null,
            userSettings: null,
            applookup:JSON.parse(sessionStorage.getItem('applookup')) ,
            isRefreshed: false

        }
    ),
    getters: {
        getUserName: (state) => state.username,
        getEmail: (state) => state.email,
        getIsAuthenticated: (state) => state.isAuthenticated ? true : false,
        getIsAdmin: (state) => state.isAuthenticated && state.isUserAdmin,
        getHomePage: (state) => state.homePage,
        getInfrastructureNext: (state) => state.infrastructureNext,
       getStructureNumbes: (state) => state.lstInfrastructure.map(
        item=>({
          structure_number: item.structure_number,
          id: item.id
          })),
        getTotalAvailableInfrastructures: (state) => state.totalAvailableInfrastructures,
        getSelectedState: (state) => state.selectedState,
    },
    actions: {
        async obtainToken({email, password}) {
            const payload = {
                email: email,
                password: password
            }

            let response = await getToken(payload);
            if (response) {
                this.isAuthenticated = true;
                this.email = payload.email;
                sessionStorage.setItem('token', JSON.stringify(response));
                sessionStorage.setItem('email', JSON.stringify(this.email));
                this.accessToken = response.access;
                this.refreshToken = response.refresh;
                this.checkIsAdmin()
                await this.fetchUserSettings()
                return response;
            } else {
                this.clearSession();
            }

        },
        async checkIsAdmin(){
            let response=await is_admin();
            if(response){
                this.isUserAdmin=response.is_admin
                if (this.isUserAdmin)
                sessionStorage.setItem('isUserAdmin', true);
            }
            else{
                this.isUserAdmin=false
            }

        },
        async updateToken() {
            const payload = {
                token: this.accessToken,
                refresh: this.refreshToken,
            }
            axios.post(this.endpoints.refreshJWT, payload)
                .then((response) => {
                    this.isAuthenticated = true;
                    sessionStorage.setItem('token', JSON.stringify(response.data));
                    this.accessToken = response.data.access;
                    this.refreshToken = response.data.refresh;
                })
                .catch((error) => {
                    this.clearSession();
                })
        },
        inspectToken() {
            const token = this.accessToken;
            if (token != null) {
                const decoded = jwt_decode(token);
                const exp = decoded.exp;
                const orig_iat = decoded.iat;
                if (exp - (Date.now() / 1000) < 900)// && (Date.now()/1000) - orig_iat < 109800)
                {
                    this.updateToken();
                } else if (exp - (Date.now() / 1000) > 2700) {
                    // DO NOTHING, DO NOT REFRESH
                } else {
                    this.clearSession();
                    // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
                }
            }
        },
        logOut() {
            this.clearSession();
        },
        async fetchUsers() {
            let response = await getAllUsers();
            if (response) {
                return response;
            }
        },
        async fetchInfra(inputParams){
            let response =await getInfrastructure(inputParams);
            if (response){
                return response
            }
        },
        async fetchBridges(inputParams) {
            let response = await getAllBridges(inputParams);
            if (response) {
                this.lstAssignedBridges = response;
                this.lstUnAssignedBridges = response;
                return response;
            }
        },

        async setLstInfrastructures(queryParams) {
            this.lstInfrastructure = []
            let infrastructureResultSet;
            infrastructureResultSet = await getInfrastructure(queryParams);
            this.lstInfrastructure = (infrastructureResultSet.results)
            this.infrastructureNext = infrastructureResultSet.links.next
            this.totalAvailableInfrastructures = infrastructureResultSet.total
            let flagGetAllData = false
            while (this.infrastructureNext != null && flagGetAllData) {
                infrastructureResultSet = await getInfrastructureNext(this.infrastructureNext);
                this.lstInfrastructure.push.apply(this.lstInfrastructure, infrastructureResultSet.results)
                this.infrastructureNext = infrastructureResultSet.links.next
            }
      this.initialStructures = this.lstInfrastructure;
        },
        async setNextInfrastructure(infrastructureNext) {
            let infrastructureResultSet;
            infrastructureResultSet = await getInfrastructureNext(infrastructureNext);
            this.lstInfrastructure.push.apply(this.lstInfrastructure, infrastructureResultSet.results)
            this.infrastructureNext = infrastructureResultSet.links.next
      
      this.initialStructures = this.lstInfrastructure;
        },
        async loadHomePage() {
            this.homePage = await loadLandingPage();
        },
        async getStructureInfo(id, year) {
            let queryParams = {
                year: year
            }
            this.structureDetails = await getInfrastructureDetails(id, queryParams);
        },
        async getLatestSurveyYear() {
            let response = await getSurveyLatestYear();
            sessionStorage.setItem('defaultYear', response.latest_survey_year);
            this.latestSurveyYear = response.latest_survey_year;
        },
        async getAvailableSurveyYears() {
            let response = await getAvailableSurveyYears();
            this.availableSurveyYearMax = response.surveyYearMax;
            this.availableSurveyYearMin = response.surveyYearMin;
        },
        async getProvisions() {
            let response = await getProvisions();
            this.provisions = response.results;
        },
        clearSession() {
            const currentTheme = sessionStorage.getItem('theme')
            sessionStorage.clear();
            this.isAuthenticated = false;
            this.email = "";
            this.accessToken = null;
            this.refreshToken = null;
            this.applookup = null;
            sessionStorage.setItem('theme', currentTheme) // setting the theme again, so that the theme is maintained on logout

    },
    filterStructures(searchItem)
    {     
        if(searchItem.trim()=='') 
            this.lstInfrastructure=this.initialStructures; 
        else
        {
        //find which matches the search item entered by user
        var matchingInfrastructures = this.lstInfrastructure.filter((item) => {
            return (
            item.structure_number.toLowerCase().includes(searchItem.toLowerCase())
            );
                });
            this.lstInfrastructure=matchingInfrastructures;
        }
    },
        async getStatisticalData()
        {
            let statistics;
            statistics = await getStatistics();
            if (statistics)
                this.statisticsData = statistics
        },
        async fetchApplookup() {
              const applookup = await getApplookup();
              if(process.env.NODE_ENV === 'development') {
                    let gridUrl = '/stateVisualization/Grid.html'
                    let adminUrl = process.env.VUE_APP_ADMIN_URL+'/admin'
                    let celeryTasksUrl = process.env.VUE_APP_ADMIN_URL+'/admin/django_celery_results/taskresult/'
                    applookup.analytics_applications.find(item => item.key === "Repair Replacement Probabilities").url = gridUrl;
                    applookup.admin_options.find(item => item.key === "Django Admin").url = adminUrl;
                    applookup.admin_options.find(item => item.key === "Celery admin").url = celeryTasksUrl;
        }
              sessionStorage.setItem("applookup", JSON.stringify(applookup))
              this.applookup = applookup
        },
        async changePassword(currentPassword, newPassword){
            let changeSuccessful = await postChangePassword(currentPassword, newPassword);
            return changeSuccessful
        },
        async fetchUserSettings() {
            let userSettings = await getUserSettings();
            if (userSettings) {
                this.userSettings = userSettings;
                let user_settings= await this.extractUserSettings(this.userSettings)
                if(user_settings["profile_pic"]) {
                    this.userAvatarPath = user_settings["profile_pic"] + '?' + Date.now();
                    sessionStorage.setItem('userAvatarPath',this.userAvatarPath);
                }
            }
        },
        async fetchDefaultSettings(){
            let defaultSettings=await getDefaultSettings();
            if (defaultSettings) {
                this.defaultSettings = defaultSettings;
                let defaultValues=this.defaultSettings
                for(var i=0;i<defaultValues.length;i++)
                  {
                      var key=defaultValues[i]["name"]
                      if (key === "Profile pic") {
                          this.userAvatarPath = defaultValues[i]["default_value"]+'?' + Date.now();
                          sessionStorage.setItem('userAvatarPath',this.userAvatarPath);
                      }
                  }
            }
        },
        async updateUserImage(event){
            let updateUserImageResponse=await updateUserImageToS3(event);
            if(updateUserImageResponse && updateUserImageResponse.data && updateUserImageResponse.data.link){
                this.userAvatarPath=updateUserImageResponse.data.link+'?' + Date.now();
            }
            return updateUserImageResponse

        },
        async updateUserDetails(request){
            let updateUserDetailsResponse=await updateUserDetails(request);
            return updateUserDetailsResponse;
        },
        async extractUserSettings(settingsArray) {
          const userSettings = {};
          settingsArray.forEach(setting => {
            const name = setting.setting.name;
            const value = setting.value;
            switch (name) {
              case "Profile pic":
                userSettings.profile_pic=value;
                break;
              default:
                console.log(`Unknown setting: ${name}`);
            }
          });
          return userSettings;
        },
        async setRefreshed(value) {
                this.isRefreshed = value;
        }
    },
});