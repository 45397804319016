<template>
  <v-menu
      v-model="menuVisible"
      :close-on-content-click="false"
      :style="computeMenuPositionStyles"
      min-height="100"
      max-height="200"
      min-width="500"
      persistent
  >
    <v-card>
      <v-card-text>
        <v-sheet class="header">
        <h6> State: {{ stateName }} - Year: {{ year }}</h6>
        <h6> Rank {{ ranking }} for {{ selectedOptionValue }}</h6>
        <v-spacer></v-spacer>
        <v-btn icon @click="hideDetails" class="close-button">
          <v-icon size="16">mdi-close</v-icon>
        </v-btn>
          </v-sheet>
        <v-tabs v-model="selectedTab">
          <v-tab v-for="(state, index) in tabs" :key="index" @click="changeTab(index)">
            {{ state }}
          </v-tab>
        </v-tabs>
        <v-container>
          <v-row v-for="(value, key) in this.stateMetrics" :key="key"
                 :class="{ 'highlighted-row': key === selectedOptionValue }">
            <v-col cols="8"><strong>{{ key }}:</strong></v-col>
            <v-col>{{ value }}</v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    selectedOptionValue: String,
    stateCode: String,
    stateName: String,
    stateMetrics: Object,
    tabs: Array,
    year: Number,
  },
  computed: {
    computeMenuPositionStyles() {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const leftOffset = this.menuPosition.x + 10;
      const maxLeft = screenWidth - 500;

      return {
        top: `${this.menuPosition.y}px`,
        left: `${Math.min(leftOffset, maxLeft)}px`,
        zIndex: 9999,
      };
    },
  },
  data() {
    return {
      menuVisible: false,
      menuPosition: {x: 0, y: 0},
      selectedTab: 0,
      ranking: -1
    };
  },
  methods: {
    showDetailsForState(event, ranking) {
      this.menuVisible = true;
      this.ranking = ranking
      this.updateMenuPosition(event)

    },
    hideDetails() {
      this.menuVisible = false;
    },
    updateMenuPosition(event) {
      this.menuPosition = {
        x: event.clientX + 10,
        y: event.clientY + 10,
      };
    },
    changeTab(index) {
      this.selectedTab = index;
    },
    handleKeyDown(event) {
      if (event.key === 'Escape') {
        this.hideDetails();
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
};
</script>

<style scoped>
.v-card {
  max-height: 200px;
  overflow-y:hidden !important;
}

.v-container {
  max-height: calc(200px - 100px);
  overflow-y: auto;
  padding: 10px;
}

.highlighted-row {
  background-color: yellow;
}

.custom-menu .v-btn {
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
}

.v-sheet.header {
  max-height: 50px;
  overflow: hidden;
}

.v-menu{
  overflow: hidden;
}
</style>
