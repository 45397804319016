<template>
  <v-container class="pa-0" id="carouselContainer" fluid data-test="carouselContainer">
    <v-carousel
        width = "100%"
        height="400"
        hide-delimiter-background
        cycle
        show-arrows="hover"
        id="carousel"
        data-test="carousel"
    >
      <v-carousel-item id="carouselImage" data-test="carouselImage"
          v-for="(image, i) in carouselImageURLs"
          :key="i"
          :src="image.src"
          cover
      >
      </v-carousel-item>
    </v-carousel>
    <v-row class="pa-24">
      <v-col cols="12" lg="6" md="12" sm="12">
        <v-card style="margin-left: 10px" flat id="contents" data-test="contents">
          <h1 v-html="contents.title"></h1>
          <p style="text-align: justify" v-html="contents.body"></p>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="12" sm="12">
        <v-card flat id="contentsImage" data-test="contentsImage">
          <v-img :src="smartRDF.url"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapActions, mapState} from "pinia";
import {useStore} from "@/store";
import {loginTheme} from '../store/theme'

export default {
  name: 'Home',
  setup(){
    const store = useStore();
    const isRefreshed = store.isRefreshed;
    if (isRefreshed){
      store.setRefreshed(false);
    loginTheme();
    }
  },
  data() {
    return {
      contents: {},
      images: [],
      smartRDF: {},
      carouselImageURLs:[{}],
    }
  },
  computed: {
    ...mapState(useStore, ['getHomePage'])
  },
  methods: {
    ...mapActions(useStore, {loadPage: 'loadHomePage'}),
    async loadcontents() {
      await this.loadPage();
      this.contents = this.getHomePage;
      this.images = this.contents.carousel_images;
      this.smartRDF = this.contents.feed_image_thumbnail;
      for (var i=0;i<this.images.length;i++)
        this.carouselImageURLs[i] = {src:this.images[i].carousels_img.url};
    },
  },
  async mounted() {
    this.loadcontents();
  },
}
</script>
<style lang="scss" scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --green: #ecfaec;
  --dark-green: #166639;
  --white: #ffffff;
  --black: #202020;
}


ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}


header {
  padding: 2em 1em;
  display: grid;
  align-items: center;
  //new change
  margin: -50px;
  padding-right: 0px;
  margin-bottom: -140px;
  margin-top: 95px;
}

header h1 {
  font-family: serif;
  color: var(--dark-green);
}

section {
  width: 100%;
  padding: 0.2em;
}

section h4 {
  opacity: 0.8;
}

li.active {
  color: var(--dark-green);
  border-bottom: 1px solid var(--dark-green);
}

.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  background-color: var(--white);
  border: 0px solid rgba(0, 0, 0, .125);
  //margin-left: 60px;
}

.card-info {
  max-height: 600px;
  text-align: justify;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  padding: 1em 2em;
  justify-items: center;
}

.card-info h1 {
  font-family: "Allura", serif;
  font-size: 5rem;
  color: var(--dark-green);
  align-self: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.card-info p {
  font-size: 1.2rem;
  align-self: center;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  line-height: 22px;
  font-family: Times New Roman;
}

.card-info a {
  grid-template-columns: max-content 20px;
  align-items: center;
  column-gap: 7px;
  color: var(--dark-green);
  font-size: 0.85rem;
  font-weight: bold;
  align-self: center;
  transform: translateX(0px);
  transition: transform 0.3s 0.1s ease-out;
}

.card-photo {
  width: 100%;
  max-height: 600px;
  overflow: hidden;
  padding: 1em 2em;
}

.card-photo img {
  display: flex;
  width: 570px;
  height: 500px;
  margin-top: 30px;
}

@media (max-width: 400px) {
  .card {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .card-info {
    order: 2;
    height: 400px;
  }
  .card-photo {
    width: 100%;
    order: 1;
  }

  .card-photo img {
    width: 100%;
  }

}

@media (max-width: 400px) {
  header {
    padding: 1em;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
  .card-info h1 {
    font-size: 3rem;
  }
}

.items {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.container {
  width: 100%;
}


/* button */
.btn_1 {

}

.btn {
  background: rgb(0, 172, 238);
  background: linear-gradient(0deg, rgba(0, 172, 238, 1) 0%, rgba(2, 126, 251, 1) 100%);
  width: 130px;
  height: 40px;
  justify-content: center;
  line-height: 40px;
  padding: 0;
  border: none;
}

.btn:before,
.btn:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}

.btn:before {
  height: 0%;
  width: 2px;
}

.btn:after {
  width: 2px;
  height: 2px;
}

.btn:hover {
  background: transparent;
  box-shadow: none;
}

.btn:hover:before {
  height: 100%;
}

.btn:hover:after {
  width: 100%;
}

.btn span:hover {
  color: rgba(2, 126, 251, 1);
}

.btn span:before,
.btn span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: rgba(2, 126, 251, 1);
  transition: all 0.3s ease;
}

.btn span:before {
  width: 2px;
  height: 0%;
}

.btn span:after {
  width: 0%;
  height: 2px;
}

.btn span:hover:before {
  height: 100%;
}

.btn span:hover:after {
  width: 100%;
}

/*carousel*/
.carousel {
  position: relative;
  max-height: 100vh;
  height: 100vh;

  .slide-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    height: 100%;
  }
}


</style>